import React, { useState, useEffect } from 'react';
import { firebaseConfig } from './config';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, getDoc, getDocs } from 'firebase/firestore';
import DataAnalysis from './DataAnalysis';
import { useNavigate } from 'react-router-dom';
import './AISearch.css';
import wordList from './wordList';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const AISearch = () => {
  const [schemas, setSchemas] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [expandedSchema, setExpandedSchema] = useState(null);
  const [showTypeAhead, setShowTypeAhead] = useState(true);
  const [showStats, setShowStats] = useState(false);
  const [fileStatistics, setFileStatistics] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [simpleAttributes, setSimpleAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchableAttributes, setSearchableAttributes] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchSchemas();
  }, []);

  useEffect(() => {
    if (searchQuery.length > 0) {
      const queryWords = searchQuery.split(' ');
      const lastWord = queryWords[queryWords.length - 1];
      const newSuggestions = wordList.filter(word =>
        word.toLowerCase().startsWith(lastWord.toLowerCase())
      );
      setSuggestions(newSuggestions.slice(0, 10)); 
    } else {
      setSuggestions([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    fetchSimpleAttributes();
  }, [selectedSchema]);

  useEffect(() => {
    fetchSearchableAttributes();
  }, [selectedSchema]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    const queryWords = searchQuery.split(' ');
    queryWords[queryWords.length - 1] = suggestion;
    setSearchQuery(queryWords.join(' '));
    setSuggestions([]);
  };

  const handleResultClick = (id) => {
    navigate(`/document-details?id=${encodeURIComponent(id)}`);
  };  

  const handleLookAheadSearch = async () => {
    setLoading(true);
    if (!searchQuery.trim()) {
      setError('Search query cannot be empty.');
      setLoading(false);
      return;
    }
    try {
      const orgId = localStorage.getItem('loggedInOrgId');
      const response = await fetch('/api/lookahead_search', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          org_id: orgId,
          schema_name: selectedSchema,
          query: searchQuery,
        }),
      });
      const data = await response.json();
      console.log('Search results:', data.results);
      setSearchResults(data.results);
    } catch (error) {
      console.error('Error during lookahead search:', error);
      setError('Error performing search.');
    } finally {
      setLoading(false);
    }
  };  

  const fetchSchemas = async () => {
    try {
      const orgId = localStorage.getItem('loggedInOrgId');
      const dataModelRef = doc(db, 'DataModel', orgId, 'schemas', 'currentSchema');
      const dataModelDoc = await getDoc(dataModelRef);
      const latestVersion = dataModelDoc.exists() ? dataModelDoc.data() : null;

      if (latestVersion && latestVersion.objectTypes) {
        const schemasData = latestVersion.objectTypes.map(objectType => ({
          schemaName: objectType.uid.split("/")[2],
          matchAttributes: objectType.matchAttributes
        }));
        setSchemas(schemasData);
      } else {
        setSchemas([]);
      }
    } catch (error) {
      console.error("Error fetching schemas: ", error);
    }
  };

  const fetchSimpleAttributes = async () => {
    if (selectedSchema) {
      const orgId = localStorage.getItem('loggedInOrgId');
      const dataModelRef = doc(db, 'DataModel', orgId, 'schemas', 'currentSchema');
      const dataModelDoc = await getDoc(dataModelRef);
      const latestVersion = dataModelDoc.exists() ? dataModelDoc.data() : null;

      if (latestVersion && latestVersion.ObjectTypes) {
        const schema = latestVersion.ObjectTypes.find(objectType => objectType.uid.split("/")[2] === selectedSchema);
        if (schema && schema.matchAttributes && schema.matchAttributes.simpleAttributes) {
          setSimpleAttributes(schema.matchAttributes.simpleAttributes);
        } else {
          setSimpleAttributes([]);
        }
      }
    }
  };

  const fetchSearchableAttributes = async () => {
    if (selectedSchema) {
      const orgId = localStorage.getItem('loggedInOrgId');
      const dataModelRef = doc(db, 'DataModel', orgId, 'schemas', 'currentSchema');
      const dataModelDoc = await getDoc(dataModelRef);
      const latestVersion = dataModelDoc.exists() ? dataModelDoc.data() : null;
  
      if (latestVersion && latestVersion.objectTypes) {
        const schema = latestVersion.objectTypes.find(objectType => objectType.uid.split("/")[2] === selectedSchema);
        if (schema && schema.attributes) {
          const searchableAttrs = getSearchableAttributes(schema.attributes);
          setSearchableAttributes(searchableAttrs);
        } else {
          setSearchableAttributes([]);
        }
      }
    }
  };
  
  const getSearchableAttributes = (attributes) => {
    const searchableAttributes = [];
  
    if (Array.isArray(attributes)) {
      attributes.forEach(attr => {
        if (attr && typeof attr === 'object') {
          const attrName = attr.name;
          const displayName = attrName?.split('.').pop();
  
          if (attr.searchable === 'Yes' || attr.searchable === true) {
            searchableAttributes.push(displayName);
          }

          if (attr.nestedAttributes && Array.isArray(attr.nestedAttributes.attrs)) {
            searchableAttributes.push(...getSearchableAttributes(attr.nestedAttributes.attrs));
          }
        }
      });
    } else {
      console.error("Expected attributes to be an array, but got:", typeof attributes);
    }
  
    return searchableAttributes;
  };  

  const handleSchemaClick = async (schemaName) => {
    if (expandedSchema === schemaName) {
      setExpandedSchema(null);
    } else {
      setExpandedSchema(schemaName);
      setSelectedAttributes([]);
    }

    setSelectedSchema(schemaName);
    setShowTypeAhead(true);
    setShowStats(false);

    const orgId = localStorage.getItem('loggedInOrgId');
    const dataModelRef = doc(db, 'DataModel', orgId, 'schemas', 'currentSchema');
    const dataModelDoc = await getDoc(dataModelRef);
    const latestVersion = dataModelDoc.exists() ? dataModelDoc.data() : null;

    if (latestVersion && latestVersion.ObjectTypes) {
      const selectedSchema = latestVersion.ObjectTypes.find(objectType => objectType.uid.split("/")[2] === schemaName);
      if (selectedSchema) {
        fetchStatistics(schemaName);
      }
    }
  };

  const handleAttributeClick = async (schemaName, attr) => {
    setShowTypeAhead(false);
    setShowStats(true);
    setSelectedAttribute(attr); 
    setSelectedSchema(schemaName);
    await fetchStatistics(schemaName);
  };

  const fetchStatistics = async (schemaName) => {
    const orgId = localStorage.getItem('loggedInOrgId');
    const fileStatistics = await getStatisticsFromFirestore(orgId, schemaName);
    setFileStatistics(fileStatistics);
  };

  const getStatisticsFromFirestore = async (orgId, schemaName) => {
    try {
      const querySnapshot = await getDocs(collection(db, 'Statistics'));
      const fileStatistics = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.orgId === orgId && data.schemaName === schemaName) {
          fileStatistics[data.fileName] = data.statistics;
        }
      });
      return fileStatistics;
    } catch (error) {
      console.error('Error retrieving statistics:', error);
      return {};
    }
  };

  const openLoadData = () => {
    navigate('/load_data');
  }

  const handleAttributeTileClick = (attribute) => {
    if (!selectedAttributes.includes(attribute)) {
      setSelectedAttributes([...selectedAttributes, attribute]);
    }
  };

  const handleRemoveAttribute = (attribute) => {
    setSelectedAttributes(selectedAttributes.filter(attr => attr !== attribute));
  };

  const handleBack = () => {
    navigate('/console?showConsole=true');
  };

  return (
    <div className="ai-search-container">
      <div className="left-panel-ai">
      <button className="button-back" onClick={handleBack}>Back</button>
        <h3>Object Types</h3>
        <ul>
          {schemas.map((schema, index) => (
            <li
              key={index}
              onClick={() => handleSchemaClick(schema.schemaName)}
              className={expandedSchema === schema.schemaName ? 'expanded' : ''}
            >
              <b>{schema.schemaName}</b>
              {expandedSchema === schema.schemaName && (
                <div className="match-attributes-ai">
                  <h4>Simple Match Attributes</h4>
                  {schema.matchAttributes.simpleAttributes && schema.matchAttributes.simpleAttributes.map((attr, index) => (
                    <div key={index} className={`attribute-ai ${selectedAttribute === attr ? 'selected' : ''}`} onClick={(e) => { e.stopPropagation(); handleAttributeClick(schema.schemaName, attr); }}>
                      {attr}
                    </div>
                  ))}
                  <h4>Complex Match Attributes</h4>
                  {schema.matchAttributes.complexAttributes && schema.matchAttributes.complexAttributes.map((attr, index) => (
                    <div key={index} className={`attribute-ai ${selectedAttribute === attr ? 'selected' : ''}`} onClick={(e) => { e.stopPropagation(); handleAttributeClick(schema.schemaName, attr); }}>
                      {attr}
                    </div>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="right-panel-ai">
        {showTypeAhead && (
          <div className="type-ahead-container">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search any data/schema....."
                value={searchQuery}
                onChange={handleInputChange}
                id="typeAheadSearch"
                name="typeAheadSearch"
              />
              <button onClick={handleLookAheadSearch} className='button'>{loading ? 'Searching...' : 'Search'}</button>
            </div>
            {searchResults.length > 0 && (
              <div className="search-results">
                {searchResults.slice(0, 3).map((result, index) => (
                  <div key={index} className="search-result-item">
                    {result.record.FirstName && result.record.FirstName.length > 0 && 
                    result.record.LastName && result.record.LastName.length > 0 && (
                      <div>
                        <a href="#" onClick={() => handleResultClick(result.documentId)}>
                          {result.record.FirstName[0].value} {result.record.LastName[0].value}
                        </a>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {suggestions.length > 0 && (
              <ul className="suggestions-list">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="suggestion-item"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
            {error && <div className="error-message">{error}</div>}
            <div className="simple-attributes-box">
              <u><div className='matchName'>Searchable Attributes</div></u>
              <div className="attribute-tiles-container">
                {searchableAttributes.map((attr, index) => (
                  <div
                    key={index}
                    className="attribute-tile"
                    onClick={() => handleAttributeTileClick(attr)}
                  >
                    {attr}
                  </div>
                ))}
              </div>
            </div>
            <div className="selected-attributes">
              {selectedAttributes.map((attr, index) => (
                <div key={index} className="selected-attribute-tile">
                  {attr}
                  <span className="remove-button" onClick={() => handleRemoveAttribute(attr)}>×</span>
                </div>
              ))}
            </div>
          </div>
        )}
        {showStats && selectedSchema && (
          <>
            <div>
              <DataAnalysis 
                showSuggestions={true} 
                selectedAttribute={selectedAttribute} 
                selectedSchema={selectedSchema} 
              />
            </div>
            <div className="statistics-container-ai">
              <h2>Statistics for {selectedSchema}</h2>
              <div className='all-stats-ai'>
                {fileStatistics && Object.keys(fileStatistics).length > 0 ? (
                  Object.keys(fileStatistics).map((file, index) => (
                    <div key={index} className="statistics-box-ai">
                      <h3>Statistics for {file}</h3>
                      <p>Simple Attributes Collections: {fileStatistics[file].simple_attributes_collections}</p>
                      <p>Complex Attributes Collections: {fileStatistics[file].complex_attributes_collections}</p>
                      <p>Embedding Dimensions: {fileStatistics[file].embedding_dimensions}</p>
                    </div>
                  ))
                ) : (
                  <div>
                    <p>No statistics for this Object. Please upload the data.</p>
                  </div>
                )}
              </div>
              <button className="load-data-button" onClick={openLoadData}>
                Load Data
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AISearch;
