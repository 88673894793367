const firebaseConfig = {
  apiKey: "AIzaSyDHUonW-a6QgBl6geuJFWAJ5rQGH06LYyM",
  authDomain: "sndbx-400bc.firebaseapp.com",
  projectId: "sndbx-400bc",
  storageBucket: "sndbx-400bc.appspot.com",
  messagingSenderId: "39427409539",
  appId: "1:39427409539:web:d47a1e68c1f07e49fefdb6",
  measurementId: "G-0NSD8XX2YF"
};

const passphrase = '160420737091';

export { firebaseConfig, passphrase};
