import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './SidePanel.css';
import { FaSignOutAlt } from 'react-icons/fa';

const SidePanel = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showConsole, setShowConsole] = useState(false);
  const [loginDate, setLoginDate] = useState('');
  const [authExpiry, setAuthExpiry] = useState('');
  const [jwtToken, setJwtToken] = useState('');
  const [timeRemaining, setTimeRemaining] = useState('');
  const [renewalStatus, setRenewalStatus] = useState('Pending');
  const [styleClass, setStyleClass] = useState('default');
  const [isJwtVisible, setIsJwtVisible] = useState(false);
  const [showJwtToken, setShowJwtToken] = useState(false);
  const [isSessionPrompted, setIsSessionPrompted] = useState(false);
  const [showLogoutPrompt, setShowLogoutPrompt] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
  
    if (params.get('showConsole') === 'true') {
      setIsExpanded(true);
      setStyleClass('compact');
      handleClickConsole();
      console.log(authExpiry);
      retrieveStatistics();
    } else {
      setStyleClass('default');
      console.log(authExpiry);
      retrieveStatistics();
    }
  }, [location]);  

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is authenticated:", user);
        setIsExpanded(true);
        setStyleClass('default');
        retrieveStatistics();
      } else {
        console.log("User not authenticated");
      }
    });
  
    return () => unsubscribe();
  }, [location]);

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    if (!showConsole) {
      setIsExpanded(false);
    }
  };

  const handleChocolateBarClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickConsole = () => {
    setShowConsole(true);
    setIsExpanded(true);
    setStyleClass('compact');
    setIsJwtVisible(false); 
    retrieveStatistics(); 
  };

  // New function to handle logout click
  const handleLogoutClick = () => {
    setShowLogoutPrompt(true);
  };

  // New function to handle logout confirmation
  const handleLogoutConfirm = (confirm) => {
    setShowLogoutPrompt(false);
    if (confirm) {
      getAuth().signOut().then(() => {
        localStorage.clear();  
        navigate('/login_page');    
      });
    }
  };

  useEffect(() => {
    const savedLoginDate = localStorage.getItem('loginDate');
    const savedAuthExpiry = localStorage.getItem('authExpiry');
    const savedJwtToken = localStorage.getItem('jwtToken');
  
    if (savedLoginDate) setLoginDate(savedLoginDate);
    if (savedAuthExpiry) setAuthExpiry(savedAuthExpiry);
    if (savedJwtToken) setJwtToken(savedJwtToken);
  
    retrieveStatistics();
  }, []);
  
  const retrieveStatistics = () => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (user) {
      setTimeout(async () => {
        console.log("Entering retrieveStatistics");
        try {
          const token = await user.getIdToken(true);
          console.log("Token retrieved:", token);
          setJwtToken(token);
          localStorage.setItem('jwtToken', token);
  
          const decodedToken = jwtDecode(token);
          const expiryDate = new Date(decodedToken.exp * 1000).toLocaleString();
          console.log("Decoded token expiry date:", expiryDate);
          setAuthExpiry(expiryDate);
          localStorage.setItem('authExpiry', expiryDate);
  
          const db = getFirestore();
          const orgId = localStorage.getItem('loggedInOrgId');
          const userRole = localStorage.getItem('isAdmin') === 'true' ? 'Admins' : 'Users';
          const docRef = doc(db, userRole, orgId);
          const docSnap = await getDoc(docRef);
  
          if (docSnap.exists()) {
            const data = docSnap.data();
            console.log("Document data:", data);
            const lastLoginTime = userRole === 'Admins' ? data?.LastLogin : data[user?.uid]?.LastLogin;
            if (lastLoginTime) {
              const loginDateValue = lastLoginTime.toDate().toLocaleString();
              console.log("Last login time:", loginDateValue);
              setLoginDate(loginDateValue);
              localStorage.setItem('loginDate', loginDateValue);
  
              const countdownTime = new Date(new Date(loginDateValue).getTime() + 60 * 60 * 1000);
              const updateCountdown = () => {
                const now = new Date();
                const difference = countdownTime - now;
  
                if (difference > 0) {
                  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                  const seconds = Math.floor((difference % (1000 * 60)) / 1000);
                  setTimeRemaining(`${minutes}m ${seconds}s`);
                } else {
                  setTimeRemaining('Expired');
                }
              };
  
              console.log("Starting countdown");
              updateCountdown();
              const intervalId = setInterval(updateCountdown, 1000);
  
              return () => clearInterval(intervalId);
            }
          }
        } catch (error) {
          console.error("Error fetching statistics:", error);
        }
      }, 2000);
    } else {
      console.log("No current user found");
    }
  };  
  
  const sessionPromptedRef = useRef(false);

  // Handle session expiry and prompt user
  const handleSessionExpiry = () => {
    console.log('Session expiry check started');
    
    sessionPromptedRef.current = true;  // Set ref flag
    console.log('sessionPromptedRef set to true');

    const stayLoggedIn = window.confirm('Your session is about to expire. Do you want to stay logged in?');
    console.log('User response to stay logged in:', stayLoggedIn);
  
    if (stayLoggedIn) {
      refreshToken();
    } else {
      handleLogout();
    }
  };

  // Refresh token and update time remaining
  const refreshToken = () => {
    console.log('Refreshing token...');
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      user.getIdToken(true)
        .then((token) => {
          console.log('Token refreshed successfully');
          setJwtToken(token);
          const decodedToken = jwtDecode(token);
          const newExpiryDate = new Date(decodedToken.exp * 1000);
          const newExpiry = newExpiryDate.toLocaleString();
          setAuthExpiry(newExpiry);
          localStorage.setItem('authExpiry', newExpiry);

          // Update countdown based on new expiry
          const updateCountdown = () => {
            const now = new Date();
            const difference = newExpiryDate - now;

            if (difference > 0) {
              const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
              const seconds = Math.floor((difference % (1000 * 60)) / 1000);
              setTimeRemaining(`${minutes}m ${seconds}s`);
            } else {
              setTimeRemaining('Expired');
            }
          };

          updateCountdown();
          const intervalId = setInterval(updateCountdown, 1000);

          // Clear interval on component unmount
          return () => clearInterval(intervalId);
        })
        .catch((error) => {
          console.error('Error refreshing token:', error);
          handleLogout();
        })
        .finally(() => {
          console.log('Session prompted ref reset after stayLoggedIn');
          sessionPromptedRef.current = false;  // Reset ref flag
        });
    } else {
      console.log('User is null, logging out');
      sessionPromptedRef.current = false;  // Reset ref flag
      handleLogout();
    }
  };

  // Handle user logout
  const handleLogout = () => {
    console.log('User logging out');
    getAuth()
      .signOut()
      .then(() => {
        console.log('User logged out, redirecting to login page');
        navigate('/login_page');
        localStorage.clear();
      })
      .finally(() => {
        console.log('Session prompted flag reset after logout');
        setIsSessionPrompted(false);
      });
  }; 

  const handleNavigation = (section) => {
    window.location.href = `admin_dashboard.html?section=${section}`;
  };

  const handleLoadData = () => {
    navigate('/load_data');
  };

  const handleAISearch = () => {
    navigate('/ai_search');
  };
  
  useEffect(() => {
    if (!showConsole) {
      setStyleClass('default');
      setIsJwtVisible(true);
      retrieveStatistics(); 
    }
  }, [showConsole]);
  
  useEffect(() => {
    console.log('useEffect triggered:', { timeRemaining, isSessionPrompted });

    if (timeRemaining === 'Expired' && !sessionPromptedRef.current) {
      console.log("Session expiry condition met, calling handleSessionExpiry");
      handleSessionExpiry();
    }
  }, [timeRemaining]);

  return (
    <div>
      <div className="custom-side-panel">
        <div
          className="custom-chocolate-bar"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleChocolateBarClick}
        >
          {[...Array(3)].map((_, row) => (
            <div key={row} className="custom-chocolate-row">
              {[...Array(3)].map((_, col) => (
                <div key={col} className="custom-chocolate-box"></div>
              ))}
            </div>
          ))}
        </div>
        {isExpanded && (
          <div className="custom-expanded-panel" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="custom-applications">
              Applications
            </div>
            <div className="custom-console-button" onClick={handleClickConsole}>
              Console
            </div>
          </div>
        )}
        {showConsole && (
          <div className="custom-console">
            <div className="custom-horizontal-tiles">
              <div className="custom-tile" onClick={() => handleNavigation('users')}>
                <img src="user_management.jpg" alt="User Management" className="custom-tile-image" />
                <div className="custom-tile-title">User Management</div>
              </div>
              <div className="custom-tile" onClick={() => handleNavigation('roles')}>
                <img src="role_management.jpg" alt="Role Management" className="custom-tile-image" />
                <div className="custom-tile-title">Role Management</div>
              </div>
              <div className="custom-tile" onClick={() => navigate('/create_schema')}>
                <img src="createSchema.jpg" alt="Create Schema" className="custom-tile-image" />
                <div className="custom-tile-title">Create Schema</div>
              </div>
              <div className="custom-tile" onClick={handleLoadData}>
                <img src="loadData.jpg" alt="Load Data" className="custom-tile-image-user" />
                <div className="custom-tile-title-user">Load Data</div>
              </div>
              <div className="custom-tile" onClick={handleAISearch}>
                <img src="AiSearch.jpg" alt="AI Search" className="custom-tile-image-user" />
                <div className="custom-tile-title-user">AI Search</div>
              </div>
            </div>
            <div className={`custom-console-statistics ${styleClass}`}>
              <div className="custom-logout-button">
                <FaSignOutAlt onClick={handleLogoutClick} />
              </div>
              <h2>Login Analytics</h2>
              <p><strong>Login Date/Time:</strong> {loginDate}</p>
              <p><strong>Auth Expiry Time:</strong> <span className="custom-countdown">{timeRemaining}</span></p>
              <p><strong>JWT Token:</strong> {styleClass === 'compact' && <button className="button" onClick={() => setShowJwtToken(!showJwtToken)}>{showJwtToken ? 'Hide' : 'Show'}</button>}</p>
              {styleClass === 'compact' && showJwtToken && <p>{jwtToken}</p>}
              <p><strong>Renewal Status:</strong> {renewalStatus}</p>
            </div>
          </div>
        )}
      </div>
      {styleClass === 'default' && (
        <div className={`custom-console-statistics ${styleClass}`}>
          <div className="custom-logout-button23">
            <FaSignOutAlt onClick={handleLogoutClick} />
          </div>
          <h2>Login Analytics</h2>
          <p><strong>Login Date/Time:</strong> {loginDate}</p>
          <p><strong>Auth Expiry Time:</strong> <span className="custom-countdown">{timeRemaining}</span></p>
          <p><strong>JWT Token:</strong> {jwtToken}</p>
          <p><strong>Renewal Status:</strong> {renewalStatus}</p>
        </div>
      )}
      {showLogoutPrompt && (
        <div className="custom-logout-prompt">
          <p>Do you want to logout?</p>
          <button onClick={() => handleLogoutConfirm(true)}>Yes</button>
          <button onClick={() => handleLogoutConfirm(false)}>No</button>
        </div>
      )}
    </div>
  );
};

export default SidePanel;
