import './styles.css';
import React, { useState } from 'react';
import { sendPasswordResetEmail, getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './config';

import bgImage1 from './login-bg1.png';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const auth = getAuth();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      alert("Reset link sent to your Email!");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="login">
      <img src={bgImage1} alt="image" className="login__bg" />

      <form name="MainForm" id="MainForm" action="" className="login__form set-height" onSubmit={handleResetPassword}>
        <h1 className="login__title">Reset Password</h1>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div className="login__inputs">
          <div className="login__box">
            <input
              type="email"
              placeholder="Enter the Email"
              id="emailInp"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="login__input"
              name='emailInp' 
              autoComplete='on'
            />
            <i className="ri-mail-fill"></i>
          </div>
        </div>

        <button type="submit" className="login__button">Reset</button>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;