import './styles.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { firebaseConfig } from './config';
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, setDoc, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import CryptoJS from 'crypto-js';
import bgImage1 from './login-bg1.png';
import TogglePasswordVisibility from './TogglePasswordVisibility';
import emailjs from 'emailjs-com';

const RegisterForm = () => {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [orgId, setOrgId] = useState('');
  const [orgName, setOrgName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validateEmail = (email) => {
    const expression = /^[^@]+@\w+(\.\w+)+\w$/
    if(expression.test(email)==true){
        //Email is good
        return true;
    }
    else{
        //Email is not good
        return false;
    }
  };

  const validatePassword = (password) => {
    if(password.length < 6){
        return false;
    }
    else{
        return true;
    }
  };

  const validateName = (fullName) => {
    if(fullName==null){
        return false;
    }
    if(fullName.length<=0){
        return false;
    }
    else{
        return true;
    }
  };

  const register = () => {
    if(validateEmail(email)==false || validatePassword(password)==false){
        alert("Please check email and password(must be atleast 6 characters)");
        return;
    }
    if(validateName(fullName)==false){
        alert("Please enter your full name");
        return;
    }
  };

  // Function to generate a unique API key
  const generateAPIKey = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-!@#$%^&*';
    let apiKey = '';
    for (let i = 0; i < 32; i++) {
      apiKey += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return apiKey;
  };

  // Function to encrypt password with AES
  const encryptWithAES = (text) => {
    return CryptoJS.AES.encrypt(text, 'passphrase').toString();
  };

  // Function to check if an admin already exists for the organization
  const checkAdminExistence = async (orgId) => {
    try {
      const adminDoc = await getDoc(doc(db, "Admins", orgId));
      return adminDoc.exists();
    } catch (error) {
      return false;
    }
  };

  // Function to send API Key using EmailJS
  const sendAPIKey = (email, apiKey) => {
    const templateParams = {
      to_email: email,
      api_key: apiKey
    };
  
    emailjs.send('service_x1nylpc', 'template_smu7jbs', templateParams, 'pfV7mLWqyywQBy1fa')
      .then(response => {
        console.log('Email sent successfully!', response.status, response.text);
      })
      .catch(error => {
        console.error('Failed to send email:', error);
      });
  };

  // Function to handle registration
  const handleRegister = async (e) => {
    e.preventDefault();

    // Validate password
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    // Encrypt password
    const encryptedPassword = encryptWithAES(password);

    try {
      // Check if admin already exists for the organization
      const adminExists = await checkAdminExistence(orgId);
      if (adminExists) {
        setErrorMessage("An Admin already exists for this organization");
        return;
      }

      // Register user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const apiKey = generateAPIKey();
      await storeAdminData(orgId, {
        FullName: fullName,
        Email: email,
        LastLogin: serverTimestamp(),
        EncryptedPassword: encryptedPassword,
        Role: 'ADMIN',
        OrganizationId: orgId,
        OrganizationName: orgName,
        APIKey: apiKey
      });

      // Send API key to the user's email
      sendAPIKey(email, apiKey);

      // Reset form fields and error message
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setFullName('');
      setOrgId('');
      setOrgName('');
      setErrorMessage('');
      
      alert(`Admin registration successful. Your API Key is sent to your email.`);

    } catch (error) {
      setErrorMessage(error.message);
      console.error("Registration error:", error);
    }
  };

  // Function to store admin data in Firestore database under the appropriate org ID
  const storeAdminData = async (orgId, data) => {
    await setDoc(doc(db, "Admins", orgId), data);
  };

  return (
    <div className="login">
      {errorMessage && <p className="error-message">{errorMessage}</p>} 
      <img src={bgImage1} alt="image" className="login__bg__reg" />

      <form name="MainForm" id="MainForm" action="" className="login__form set-height" onSubmit={handleRegister}>
        <h1 className="login__title">Admin Register</h1>

        <div className="login__inputs">
          <div className="login__box">
            <input type="email" placeholder="Username/Email ID" value={email} onChange={(e) => setEmail(e.target.value)} required className="login__input" name='email' autoComplete='on' />
            <i className="ri-mail-fill"></i>
          </div>

          <div className="login__box">
            <input type="password" placeholder="Set Password" value={password} id="passwordInp" onChange={(e) => setPassword(e.target.value)} required className="login__input" name='password' autoComplete='off'/><TogglePasswordVisibility inputId="passwordInp" />
            {/* <i className="ri-lock-2-fill" id="togglePassword"></i> */}
          </div>

          <div className="login__box">
            <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required className="login__input" name='confirmPassword' autoComplete='off' />
          </div>

          <div className="login__box">
            <input type="text" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} required className="login__input" name='fullName' autoComplete='on'/>
          </div>

          <div className="login__box">
            <input type="text" placeholder="Organization ID" value={orgId} onChange={(e) => setOrgId(e.target.value)} required className="login__input" name='orgId' autoComplete='on' />
          </div>

          <div className="login__box">
            <input type="text" placeholder="Organization Name" value={orgName} onChange={(e) => setOrgName(e.target.value)} required className="login__input" name='orgName' autoComplete='on'/>
          </div>
        </div>

        <button type="submit" className="login__button" onClick={register}>Register</button>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div className="login__register">
          Already have an account?
        </div>
        <Link to="/login_page">
          <button type="button" className="login__button">Login/Sign In</button>
        </Link>
      </form>
    </div>
  );
};

export default RegisterForm;