import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { firebaseConfig } from './config';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';
import './LoadData.css';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const LoadData = () => {
  const navigate = useNavigate();
  const [uploadMethod, setUploadMethod] = useState('');
  const [files, setFiles] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [selectedObjectType, setSelectedObjectType] = useState(null);
  const [gcsCredentials, setGcsCredentials] = useState({ key: '', bucket: '', fileName: '', fileExtension: 'json' });
  const [awsCredentials, setAwsCredentials] = useState({ accessKeyId: '', secretAccessKey: '', bucket: '', fileName: '', fileExtension: 'json' });
  const [azureCredentials, setAzureCredentials] = useState({ accountName: '', accountKey: '', container: '' });
  const [buttonText, setButtonText] = useState('Submit');
  const [showJson, setShowJson] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [statistics, setStatistics] = useState(null);
  const [fileStatistics, setFileStatistics] = useState({});
  const [fileName, setFileName] = useState(''); 
  const [showForm, setShowForm] = useState(false); 
  const [showStats, setShowStats] = useState(true); 
  const [oLoading, setOLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [error, setError] = useState('');

  const sampleJson =   [{
    "type": "datamodel/objectTypes/Individual",
    "attributes": {
      "FirstName": [
        {
          "value": "FN100"
        }
      ],
      "LastName": [
        {
          "value": "LN100"
        }
      ],
      "Address": [
        {
          "value": {
            "AddressType": [
              {
                "value": "Business"
              }
            ],
            "AddressLine1": [
              {
                "value": "101 Main Street"
              }
            ],
            "City": [
              {
                "value": "New York"
              }
            ],
            "StateProvince": [
              {
                "value": "NY"
              }
            ],
            "Zip": [
              {
                "value": {
                  "Zip5": [
                    {
                      "value": "10001"
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    },
    "source": [
      {
        "type": "datamodel/sources/FaceBook",
        "value": "FB1000"
      }
    ]
  }];

  useEffect(() => {
    const fetchSchemasAndStatistics = async () => {
      try {
        const orgId = localStorage.getItem('loggedInOrgId');
        const dataModelRef = doc(db, 'DataModel', orgId, 'schemas', 'currentSchema');
        const dataModelDoc = await getDoc(dataModelRef);
        const latestVersion = dataModelDoc.exists() ? dataModelDoc.data() : null;
  
        if (latestVersion && latestVersion.objectTypes) {
          const schemasData = latestVersion.objectTypes.map(objectType => ({
            schemaName: objectType.uid.split("/")[2],
            attributes: objectType.attributes.map(attr => ({
              name: attr.name,
              type: attr.type || "",
              matchValue: attr.matchValue || ""
            }))
          }));
          setSchemas(schemasData);
  
          // Fetch statistics for each schema
          const allFileStatistics = {};
          for (const schema of schemasData) {
            const fileStatistics = await getStatisticsFromFirestore(orgId, schema.schemaName);
            allFileStatistics[schema.schemaName] = fileStatistics;
          }
          setFileStatistics(allFileStatistics);
        } else {
          setSchemas([]);  
        }
      } catch (error) {
        console.error("Error fetching schemas or statistics: ", error);
      }
    }; 
    fetchSchemasAndStatistics();
  }, []);  

  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setFiles(uploadedFiles);
  
    if (uploadedFiles.length > 0) {
      setFileName(uploadedFiles.map(file => file.name).join(', ')); 
    }
  };  

  const handleUploadMethodChange = (event) => {
    setError('');
    setUploadMethod(event.target.value);
  };

  // Function to save statistics to Firestore
  const saveStatisticsToFirestore = async (orgId, schemaName, fileName, statistics) => {
    try {
      await setDoc(doc(db, 'Statistics', `${orgId}_${schemaName}_${fileName}`), {
        orgId,
        schemaName,
        fileName,
        statistics,
      });
    } catch (error) {
      console.error('Error saving statistics:', error);
    }
  };

  // Function to retrieve statistics from Firestore
  const getStatisticsFromFirestore = async (orgId, schemaName) => {
    try {
      const querySnapshot = await getDocs(collection(db, 'Statistics'));
      const fileStatistics = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.orgId === orgId && data.schemaName === schemaName) {
          fileStatistics[data.fileName] = data.statistics;
        }
      });
      return fileStatistics;
    } catch (error) {
      console.error('Error retrieving statistics:', error);
      return {};
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setText('Uploading...');
    setOLoading(true); 
    setUploadSuccess(false);
  
    await new Promise(resolve => setTimeout(resolve, 3000));
  
    let response;
    const orgId = localStorage.getItem('loggedInOrgId');
    const selectedSchemaName = selectedObjectType.schemaName;
  
    if (uploadMethod === 'device' && files.length === 0) {
      setError('Please select any file!');
      setOLoading(false);
      setText('');
      return;
    }
  
    if (uploadMethod === 'gcs') {
      response = await handleGcsFetch(selectedSchemaName, gcsCredentials);
    } else if (uploadMethod === 'device') {
      response = await handleDeviceUpload(selectedSchemaName, orgId);
    } else if (uploadMethod === 'aws') {
      response = await handleAwsUpload(selectedSchemaName, awsCredentials);
    } else if (uploadMethod === 'azure') {
      response = await handleAzureFetch(selectedSchemaName, azureCredentials);
    } else {
      setError('Please select any upload method.');
      setOLoading(false);
      setText('');
      return;
    }
  
    if (response && response.message === 'Files uploaded successfully.') {
      let currentFileNames = '';
  
      if (uploadMethod === 'device' && files.length > 0) {
        currentFileNames = files.map(file => file.name).join(', ');
      } else if (uploadMethod === 'gcs' && gcsCredentials.fileName && gcsCredentials.fileExtension) {
        currentFileNames = `${gcsCredentials.fileName}.${gcsCredentials.fileExtension}`;
      } else if (uploadMethod === 'aws' && awsCredentials.fileName && awsCredentials.fileExtension) {
        currentFileNames = `${awsCredentials.fileName}.${awsCredentials.fileExtension}`;
      } else if (uploadMethod === 'azure' && azureCredentials.blobName) {
        currentFileNames = azureCredentials.blobName;
      } else if (fileName) {
        currentFileNames = fileName;
      }
  
      console.log(currentFileNames);
  
      setFileStatistics((prevFileStatistics) => ({
        ...prevFileStatistics,
        [selectedObjectType.schemaName]: {
          ...prevFileStatistics[selectedObjectType.schemaName],
          [currentFileNames]: response.statistics,
        },
      }));
  
      // Save the statistics to Firestore
      await saveStatisticsToFirestore(orgId, selectedSchemaName, currentFileNames, response.statistics);
  
      setFiles([]);
      setGcsCredentials({ key: '', bucket: '', fileName: '', fileExtension: 'json' });
      setAwsCredentials({ accessKeyId: '', secretAccessKey: '', bucket: '', fileName: '', fileExtension: 'json' });
      setAzureCredentials({ accountName: '', accountKey: '', container: '', blobName: '' });
  
      setUploadSuccess(true);
      setOLoading(false);
      setLoading(false);
      setShowForm(false);
      setShowStats(true);
      setFileName('');
      setText('');
      setButtonText('Submit');
    } else if (response && response.error) {
      alert(response.error);
    }
  };
  
  const handleDeviceUpload = async (selectedSchemaName, orgId) => {
    try {
      const formData = new FormData();
      files.forEach(file => formData.append('files', file)); 
      formData.append('orgId', orgId); 
      formData.append('schemaName', selectedSchemaName);
      
      setOLoading(false); 
      setLoading(true); 
      setText('');
      setButtonText('Submit');
      setShowForm(false); 
      setShowStats(true); 

      const response = await fetch('/api/upload', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Validation Errors:", errorData.errors || errorData.detail); 
        alert("Errors: " + JSON.stringify(errorData.detail)); 
        setLoading(false); 
        setFileName('');
        setText('');
        setButtonText('Submit');
        return;
      }
  
      const responseData = await response.json();
      console.log('Backend response:', responseData);
      setStatistics(responseData);
      setUploadSuccess(true); 
      return responseData;
    } catch (error) {
      console.error('Error uploading files from device:', error);
      alert('Please upload the files correctly!');
    }
  };  

  const handleGcsFetch = async (selectedSchemaName, gcsCredentials) => {
    const orgId = localStorage.getItem('loggedInOrgId');
  
    try {
        const response = await fetch('/api/fetch-gcs-file', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                key: gcsCredentials.key,
                bucket: gcsCredentials.bucket,
                fileName: `${gcsCredentials.fileName}.${gcsCredentials.fileExtension}`,
                orgId: orgId,
                schemaName: selectedSchemaName,
            }),
        });
  
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        setStatistics(responseData);
        const currentFileName = `${gcsCredentials.fileName}.${gcsCredentials.fileExtension}`;
        setFileName(currentFileName); 
        setUploadSuccess(true);
        return responseData;
    } catch (error) {
        console.error('Error fetching data from GCS:', error);
        alert('Please Enter details correctly!');
    }
  };
  
  const handleAwsUpload = async (selectedSchemaName, awsCredentials) => {
    const orgId = localStorage.getItem('loggedInOrgId'); 
  
    try {
      const response = await fetch('/api/fetch-aws-file', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accessKeyId: awsCredentials.accessKeyId,
          secretAccessKey: awsCredentials.secretAccessKey,
          bucket: awsCredentials.bucket,
          fileName: `${awsCredentials.fileName}.${awsCredentials.fileExtension}`,
          orgId: orgId, 
          schemaName: selectedSchemaName 
        }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      setStatistics(responseData);
      const currentFileName = `${awsCredentials.fileName}.${awsCredentials.fileExtension}`;
      setFileName(currentFileName);  
      setUploadSuccess(true);
      return responseData;
    } catch (error) {
      console.error('Error uploading files to AWS S3:', error);
      alert('Error uploading to AWS S3. Please check your credentials and bucket name.');
    }
  };

  const handleAzureFetch = async (selectedSchemaName, azureCredentials) => {
    const orgId = localStorage.getItem('loggedInOrgId');
  
    try {
      const response = await fetch('/api/fetch-azure-blob', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accountName: azureCredentials.accountName,
          accountKey: azureCredentials.accountKey,
          container: azureCredentials.container,
          blobName: azureCredentials.blobName,
          orgId: orgId,
          schemaName: selectedSchemaName,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      setStatistics(responseData);
      const currentFileName = azureCredentials.blobName;
      setFileName(currentFileName);
      setUploadSuccess(true);
      return responseData;
    } catch (error) {
      console.error('Error fetching data from Azure:', error);
      alert('Please enter details correctly!');
    }
  };

  const handleObjectTypeClick = (objectType) => {
    if (selectedObjectType === objectType) {
      // setSelectedObjectType(null);
      setShowForm(false);
      setShowStats(true);
    } else {
      setSelectedObjectType(objectType);
      setShowForm(false);
      setShowStats(true);
    }
  };  

  useEffect(() => {
    document.body.classList.add('load-data-body');

    return () => {
      document.body.classList.remove('load-data-body');
    };
  }, []);

  const handleBack = () => {
    navigate('/console?showConsole=true');
  };

  return (
    <div className="load-data-container">
      <div className="left-side-panel">
      <button className="button-back" onClick={handleBack}>Back</button>
        <u><h3>Load Data</h3></u>
        <h4>Select Object Type from the DataModel</h4>
        <hr />
        <div className="objectTypes1">
          <ul className="ul-list">
            {schemas.map((schema, index) => (
              <li
                key={index}
                className={`objectValues1 object-type-item ${selectedObjectType === schema ? 'selected' : ''}`}
                onClick={() => handleObjectTypeClick(schema)}
              >
                {schema.schemaName}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="right-side-panel">
        {selectedObjectType && showStats && (
          <div className='statistics-container'>
            <h2>Statistics for {selectedObjectType.schemaName}</h2>
            <div className='all-stats'>
              {loading ? (
                <div>
                  <div className='text-upload'>Your data is uploaded. Please wait for the statistics to be retrieved.</div>
                  <div className="loading-spinner-data"></div>
                </div>
              ) : (
                fileStatistics[selectedObjectType.schemaName] && Object.keys(fileStatistics[selectedObjectType.schemaName]).length > 0 ? (
                  Object.keys(fileStatistics[selectedObjectType.schemaName]).map((fileNames, index) => (
                    <div key={index} className="statistics-box">
                      <h3>Statistics for {fileNames}</h3>
                      {fileStatistics[selectedObjectType.schemaName][fileNames] ? (
                        <>
                          <p>Simple Attributes Collections: {fileStatistics[selectedObjectType.schemaName][fileNames].simple_attributes_collections}</p>
                          <p>Complex Attributes Collections: {fileStatistics[selectedObjectType.schemaName][fileNames].complex_attributes_collections}</p>
                          <p>Embedding Dimensions: {fileStatistics[selectedObjectType.schemaName][fileNames].embedding_dimensions}</p>
                        </>
                      ) : (
                        <div className='text-upload'>Please wait, fetching the statistics...</div>
                      )}
                    </div>
                  ))
                ) : (
                  <div>
                    <p>No statistics for this Object. Please upload the data.</p>
                  </div>
                )
              )}
            </div>
            {!showForm && (
              <button className="load-data-button" onClick={() => { setShowForm(true); setShowStats(false); }}>
                Load Data
              </button>
            )}
          </div>
        )}
      {showForm && selectedObjectType && (
        <>
          <form onSubmit={handleSubmit} className="form">
                <h2>Load Data for {selectedObjectType.schemaName}</h2><br></br>
                  <div className="upload-method">
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="device"
                        checked={uploadMethod === 'device'}
                        onChange={handleUploadMethodChange}
                      />
                      Upload from Device
                    </label>
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="gcs"
                        checked={uploadMethod === 'gcs'}
                        onChange={handleUploadMethodChange}
                      />
                      Upload from Google Cloud Storage
                    </label>
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="aws"
                        checked={uploadMethod === 'aws'}
                        onChange={handleUploadMethodChange}
                      />
                      Upload from Amazon AWS
                    </label>
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="azure"
                        checked={uploadMethod === 'azure'}
                        onChange={handleUploadMethodChange}
                      />
                      Upload from Azure
                    </label>
                  </div>

                  {uploadMethod === 'device' && (
                    <div className="file-upload">
                      <input type="file" multiple onChange={handleFileUpload} />
                    </div>
                  )}
                  {uploadMethod === 'gcs' && (
                    <div className="credentials">
                      <input
                        type="text"
                        name="bucket"
                        placeholder="GCS Bucket Name"
                        value={gcsCredentials.bucket}
                        onChange={(e) => setGcsCredentials({ ...gcsCredentials, bucket: e.target.value })}
                        required
                      />
                      <textarea
                        placeholder="Paste your GCS Service Account JSON Key here"
                        value={gcsCredentials.key}
                        onChange={(e) => setGcsCredentials({ ...gcsCredentials, key: e.target.value })}
                        required
                        rows="6"
                      />
                      <div className="file-name-container">
                        <input
                          type="text"
                          name="fileName"
                          placeholder="File Name"
                          value={gcsCredentials.fileName}
                          onChange={(e) => setGcsCredentials({ ...gcsCredentials, fileName: e.target.value })}
                          required
                        />
                        <select
                          name="fileExtension"
                          value={gcsCredentials.fileExtension}
                          onChange={(e) => setGcsCredentials({ ...gcsCredentials, fileExtension: e.target.value })}
                          required
                        >
                          <option value="json">JSON</option>
                          <option value="csv">CSV</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  )}
                  {uploadMethod === 'aws' && (
                    <div className="credentials">
                      <input
                        type="text"
                        placeholder="AWS Access Key ID"
                        value={awsCredentials.accessKeyId}
                        onChange={(e) => setAwsCredentials({ ...awsCredentials, accessKeyId: e.target.value })}
                        required
                      />
                      <input
                        type="password"
                        placeholder="AWS Secret Access Key"
                        value={awsCredentials.secretAccessKey}
                        onChange={(e) => setAwsCredentials({ ...awsCredentials, secretAccessKey: e.target.value })}
                        required
                      />
                      <input
                        type="text"
                        placeholder="AWS Bucket Name"
                        value={awsCredentials.bucket}
                        onChange={(e) => setAwsCredentials({ ...awsCredentials, bucket: e.target.value })}
                        required
                      />
                      <div className="file-name-container">
                        <input
                          type="text"
                          placeholder="File Name"
                          value={awsCredentials.fileName}
                          onChange={(e) => setAwsCredentials({ ...awsCredentials, fileName: e.target.value })}
                          required
                        />
                        <select
                          name="fileExtension"
                          value={awsCredentials.fileExtension}
                          onChange={(e) => setAwsCredentials({ ...awsCredentials, fileExtension: e.target.value })}
                          required
                        >
                          <option value="json">JSON</option>
                          <option value="csv">CSV</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  )}
                  {uploadMethod === 'azure' && (
                    <div className="credentials">
                      <input
                        type="text"
                        placeholder="Azure Account Name"
                        value={azureCredentials.accountName}
                        onChange={(e) => setAzureCredentials({ ...azureCredentials, accountName: e.target.value })}
                        required
                      />
                      <input
                        type="password"
                        placeholder="Azure Account Key"
                        value={azureCredentials.accountKey}
                        onChange={(e) => setAzureCredentials({ ...azureCredentials, accountKey: e.target.value })}
                        required
                      />
                      <input
                        type="text"
                        placeholder="Azure Container Name"
                        value={azureCredentials.container}
                        onChange={(e) => setAzureCredentials({ ...azureCredentials, container: e.target.value })}
                        required
                      />
                      <input
                        type="text"
                        placeholder="Azure Blob Name"
                        value={azureCredentials.blobName}
                        onChange={(e) => setAzureCredentials({ ...azureCredentials, blobName: e.target.value })}
                        required
                      />
                    </div>
                  )}
                  <p className="note">
                    Please check the data model from the CreateSchema page to look at the exact attributes of the object types.If the data model attributes don't match, it is invalid. Here is the sample <strong>actual data.json</strong>,which represents the actual data format to upload here.
                  </p>
                  <button type="button" className="view-json-button" onClick={() => setShowJson(true)}>
                    View Sample Data.json
                  </button>

                  {showJson && (
                    <div className="json-modal">
                      <div className="json-modal-content">
                        <span className="close-button" onClick={() => setShowJson(false)}>&times;</span>
                        <pre className="json-editor">
                          {JSON.stringify(sampleJson, null, 2)}
                        </pre>
                      </div>
                    </div>
                  )}
                  <div className='error-msg'>{error}</div>
                <div className='text-upload'>{text}</div>
                {oLoading && <div className="loading-spinner-data"></div>}
                {!oLoading && (
                  <button type="submit" className="submit-button">
                    {buttonText}
                  </button>
                )}
                </form>
              </>
            )}
      </div>
    </div>
  );
};

export default LoadData;
