import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; 
import { FaEdit, FaPlusCircle, FaSync, FaExclamationCircle, FaEye } from 'react-icons/fa';
import './DocumentDetails.css';

const DocumentDetails = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const [documentData, setDocumentData] = useState([]);
  const [originalDocument, setOriginalDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedDocument, setEditedDocument] = useState({});
  const [history, setHistory] = useState([]);
  const [activeVersion, setActiveVersion] = useState(null);
  const [viewMode, setViewMode] = useState('view');
  const [selectedTimestamp, setSelectedTimestamp] = useState(null);
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  const [showLabelBox, setShowLabelBox] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');  

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        setLoading(true); 
        const cleanId = decodeURIComponent(id).replace(/[#]/g, '');
        const orgId = localStorage.getItem('loggedInOrgId'); 
  
        if (!orgId) {
          throw new Error('Organization ID is not set in localStorage');
        }
  
        const response = await fetch(`/api/document?id=${cleanId}&orgId=${orgId}`);
        if (!response.ok) {
          throw new Error('Document not found');
        }
  
        const data = await response.json();
        setDocumentData([data.document]);
        setOriginalDocument([data.document]); // Store the original document

        await fetchHistory(cleanId, data.document.type);
      } catch (err) {
        setError(err.message || 'Error fetching document.');
      } finally {
        setLoading(false);
      }
    };
  
    if (id) {
      fetchDocument();
    }
  }, [id]);
  
  const fetchHistory = async (documentId, type) => {
    try {
      const response = await fetch(`/api/document-history?documentId=${documentId}&type=${type}`);
      if (!response.ok) {
        throw new Error('History not found');
      }
      const data = await response.json();
      setHistory(data.map((entry, index) => ({
        ...entry,
        versionLabel: index === 0 ? 'created' : 'updated',
      })));
    } catch (err) {
      console.error('Error fetching history:', err.message);
      setError('Error fetching history');
    }
  };  

  const handleBack = () => {
    navigate('/ai_search');
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditedDocument(structuredClone(documentData[index])); 
  };

  const handleInputChange = (event, fieldPath) => {
    const { value } = event.target;
    console.log(`Input change - Field Path: ${fieldPath}, New Value: ${value}`);
  
    const keys = fieldPath.split('.');
    const updatedDocument = structuredClone(editedDocument);
  
    const setNestedValue = (obj, keys, value) => {
      const lastKey = keys.pop();
      const lastObj = keys.reduce((obj, key) => {
        const arrayMatch = key.match(/(\w+)\[(\d+)\]/);
        if (arrayMatch) {
          const arrayKey = arrayMatch[1];
          const index = parseInt(arrayMatch[2], 10);
          return obj[arrayKey][index];
        }
        return obj[key];
      }, obj);
      
      if (lastObj[lastKey] && typeof lastObj[lastKey] === 'object' && !Array.isArray(lastObj[lastKey])) {
        lastObj[lastKey] = value || {}; // Ensure it is an object if it was an object
      } else {
        lastObj[lastKey] = value || ''; // Handle empty strings
      }
    };
  
    setNestedValue(updatedDocument, keys, value);
    setEditedDocument(updatedDocument);
    console.log('Updated Document:', updatedDocument);
  };  
  
  const handleSave = async () => {
    try {
      const response = await fetch(`/api/update-document`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: documentData[editingIndex]._id,
          data: editedDocument,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error updating document');
      }
  
      const updatedData = await response.json();
  
      setDocumentData((prevState) => {
        const newState = [...prevState];
        newState[editingIndex] = updatedData;
        return newState;
      });

      setOriginalDocument((prevState) => {
        const newState = [...prevState];
        newState[editingIndex] = updatedData;
        return newState;
      });
  
      setEditingIndex(null);
      setEditedDocument({});
  
      // Fetch and update history after saving
      await fetchHistory(documentData[editingIndex]._id, updatedData.type); 
    } catch (err) {
      setError(err.message || 'Error saving document.');
    }
  };  

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, key) => {
      const arrayMatch = key.match(/(\w+)\[(\d+)\]/);
      if (arrayMatch) {
        const arrayKey = arrayMatch[1];
        const index = parseInt(arrayMatch[2], 10);
        return acc && acc[arrayKey] ? acc[arrayKey][index] : '';
      }
      return acc ? acc[key] : '';
    }, obj) || '';
  };  

  const extractStyle = (item) => {
    if (typeof item === 'object' && item !== null) {
      if (item.style) {
        console.log(`Extracted style for ${JSON.stringify(item.value)}:`, item.style);
        return item.style;
      } else if (item.value && typeof item.value === 'object') {
        return extractStyle(item.value);
      }
    }
    console.log(`No style found for ${JSON.stringify(item)}`);
    return {};
  };  

  const renderStyledFields = (data, prefix = '', isEditing = false) => {
    const nonEditableFields = ['_id', 'type', 'source'];
  
    return Object.entries(data).map(([key, value]) => {
      const fieldPath = prefix ? `${prefix}.${key}` : key;
      const isReadOnly = nonEditableFields.includes(key);
  
      if (Array.isArray(value)) {
        return (
          <div key={fieldPath} className="form-group">
            <div className="flex-wrapper">
              <h4 style={extractStyle(value[0])}>{key} :</h4>
              {value.length > 0 ? (
                value.map((item, index) => (
                  <div key={`${fieldPath}[${index}]`} className="nested-item">
                    {renderStyledFields(item, `${fieldPath}[${index}]`, isEditing)}
                  </div>
                ))
              ) : (
                <p>No items</p>
              )}
            </div>
          </div>
        );
      } else if (typeof value === 'object' && value !== null) {
        const style = value.style && value.style.color === 'red' ? {} : value.style;
  
        if (Object.keys(value).length === 0 && !Array.isArray(value)) {
          return (
            <div key={fieldPath} className={`form-group ${prefix.includes('attributes') ? 'inline-field-group' : ''}`}>
              <label style={extractStyle(value)}>{key === 'value' ? '' : key}</label>
              {isEditing ? (
                <input
                  type="text"
                  value={typeof getNestedValue(editedDocument, fieldPath) === 'object' ? '' : getNestedValue(editedDocument, fieldPath) || ''}
                  onChange={(e) => handleInputChange(e, fieldPath)}
                  className={`form-control ${prefix.includes('attributes') ? 'inline-field' : ''}`}
                  readOnly={isReadOnly}
                  style={extractStyle(value)}
                />
              ) : (
                <div className="form-control-wrapper">
                  <input
                    type="text"
                    value=""
                    readOnly
                    className={`form-control ${prefix.includes('attributes') ? 'inline-field' : ''}`}
                    style={extractStyle(value)}
                  />
                  <span className="data-not-available">✘ Data not available</span>
                </div>
              )}
            </div>
          );
        }
  
        if (key === 'value' && value.style) {
          return (
            <div key={fieldPath} className={`form-group ${prefix.includes('attributes') ? 'inline-field-group' : ''}`}>
              <label>{prefix.includes('attributes') && key === 'value' ? null : key}</label>
              <input
                type="text"
                value={typeof value === 'object' && value.value ? value.value : ''}
                onChange={(e) => handleInputChange(e, fieldPath)}
                className={`form-control ${prefix.includes('attributes') ? 'inline-field' : ''}`}
                readOnly={!isEditing || isReadOnly}
                style={extractStyle(value)} 
              />
            </div>
          );
        }
  
        if (key !== 'attributes') {
          return (
            <div key={fieldPath} className="form-group">
              <label>{key === 'value' ? '' : key}</label>
              {renderStyledFields(value, fieldPath, isEditing)}
            </div>
          );
        }
  
        return renderStyledFields(value, fieldPath, isEditing);
      }
  
      const isEmptyValue =
        value === '' ||
        (typeof value === 'object' && value !== null && Object.keys(value).length === 0) ||
        value === null;
  
      return (
        <div key={fieldPath} className={`form-group ${prefix.includes('attributes') ? 'inline-field-group' : ''}`}>
          <label style={extractStyle(value)}>
            {prefix.includes('attributes') && key === 'value' ? null : key}
            {isEmptyValue && <FaExclamationCircle className="icon-missing-data" />}
          </label>
          {isEditing ? (
            <input
              type="text"
              value={typeof getNestedValue(editedDocument, fieldPath) === 'object' ? '' : getNestedValue(editedDocument, fieldPath) || ''}
              onChange={(e) => handleInputChange(e, fieldPath)}
              className={`form-control ${prefix.includes('attributes') ? 'inline-field' : ''}`}
              readOnly={isReadOnly}
              style={extractStyle(value)}
            />
          ) : (
            <input
              type="text"
              value={isEmptyValue ? '' : value}
              readOnly
              className={`form-control ${prefix.includes('attributes') ? 'inline-field' : ''}`}
              style={extractStyle(value)}
            />
          )}
        </div>
      );
    });
  };  
   
  // const toggleHistoryEntry = (index) => {
  //   setActiveVersion((prevActiveVersion) =>
  //     prevActiveVersion === index ? null : index
  //   );
  // };

  //{renderFields(entry.data)} instead of pre json stringify

  const renderHistory = () => {
    return history.map((entry, index) => (
      <div key={index} className="history-entry">
        <div className="history-header">
          <a href="#" onClick={() => toggleHistoryEntry(index, entry.updated_data || entry.data)} className="version-toggle">
            {new Date(entry.timestamp).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}
          </a>
          <span className="version-label">
            {entry.versionLabel === 'created' ? (
              <FaPlusCircle className="icon-created" alt="Created" />
            ) : (
              <FaSync className="icon-updated" alt="Updated" />
            )}
            {entry.versionLabel === 'created' ? ' C' : ' U'}
          </span>
        </div>
        {activeVersion === index && (
          <div className="version-details">
            {/* <pre>{JSON.stringify(entry.updated_data || entry.data, null, 2)}</pre> */}
            <p>{new Date(entry.timestamp).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</p>
          </div>
        )}
      </div>
    ));
  };

  // Modified compareAttributes function to directly apply color styling in the component rendering
  const compareAttributes = (original, current) => {
    const highlightedData = structuredClone(current);
  
    const traverseAndCompare = (origObj, currObj, path = '') => {
      Object.keys(currObj).forEach((key) => {
        const fullPath = path ? `${path}.${key}` : key;
  
        if (typeof currObj[key] === 'object' && !Array.isArray(currObj[key]) && currObj[key] !== null) {
          // If it's a nested object, recurse
          traverseAndCompare(origObj[key] || {}, currObj[key], fullPath);
        } else if (Array.isArray(currObj[key])) {
          // If it's an array, check each item in the array
          currObj[key].forEach((item, index) => {
            traverseAndCompare(origObj[key] && origObj[key][index] ? origObj[key][index] : {}, item, `${fullPath}[${index}]`);
          });
        } else {
          // Compare scalar values
          if (!(key in origObj)) {
            // Key is new in the current object - highlight in red
            currObj[key] = { value: currObj[key], style: { color: 'red' } };
          } else if (origObj[key] !== currObj[key]) {
            // Values are different - highlight in blue
            currObj[key] = { value: currObj[key], style: { color: 'blue' } };
          }
        }
      });
  
      // Handle deleted attributes in the original object but missing in the current object
      Object.keys(origObj).forEach((key) => {
        if (!(key in currObj)) {
          currObj[key] = { value: origObj[key], style: { color: 'red' } }; // Deleted attributes in red
        }
      });
    };
  
    traverseAndCompare(original, highlightedData);
    return highlightedData;
  };
  
  const toggleHistoryEntry = (index, data) => {
    if (index === activeVersion) {
      setActiveVersion(null);
      setSelectedTimestamp(null); // Clear the timestamp when toggling off
      setDocumentData(originalDocument); // Reset to the original document data
      setIsDropdownDisabled(false); // Enable the dropdown
      setShowLabelBox(false); // Hide the label box when toggling off
    } else {
      setActiveVersion(index);
      setSelectedTimestamp(history[index].timestamp); // Set the selected timestamp
      const comparedData = compareAttributes(originalDocument[0], data);
      console.log(JSON.stringify(comparedData));
      setDocumentData([comparedData]); // Set to the highlighted version
      setIsDropdownDisabled(true); // Disable the dropdown
      setShowLabelBox(true); // Show the label box when a timestamp is selected
    }
  };
  
  return (
    <div className="document-details-container">
      <button className="button-back" onClick={handleBack}>Back</button>
      <h3>Document Details</h3>
      <div className="document-content">
        <div className="document-data">
          {documentData.length > 0 ? (
            documentData.map((doc, index) => (
              <div key={index} className={`document-section ${editingIndex === index ? 'editing' : ''}`}>
                <u>
                  <h4 style={{ display: 'inline-block' }}>Document:</h4>
                </u>
                {selectedTimestamp && (
                  <span className="selected-timestamp">
                    {new Date(selectedTimestamp).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}
                  </span>
                )}
                <div className="mode-switch">
                  {viewMode === 'view' ? (
                    <FaEye className="mode-icon" />
                  ) : (
                    <FaEdit className="mode-icon" />
                  )}
                  <select
                    value={viewMode}
                    onChange={(e) => {
                      const selectedMode = e.target.value;
                      if (selectedMode === 'edit') {
                        handleEdit(index);
                      } else {
                        setEditingIndex(null);
                      }
                      setViewMode(selectedMode);
                    }}
                    className="mode-select"
                    disabled={isDropdownDisabled}
                  >
                    <option value="view">View</option>
                    <option value="edit">Edit</option>
                  </select>
                </div>
                {showLabelBox && (
                  <div className="label-box">
                    <div className="label-item">
                      <span className="circle red-circle"></span> Missing
                    </div>
                    <div className="label-item">
                      <span className="circle blue-circle"></span> Updated
                    </div>
                  </div>
                )}
                {renderStyledFields(doc, '', editingIndex === index)}
                {editingIndex === index && (
                  <button className="button-save" onClick={handleSave}>Save</button>
                )}
              </div>
            ))
          ) : (
            <p>No documents found</p>
          )}
        </div>
        <div className="document-history">
          <h3>History</h3>
          {history.length > 0 ? renderHistory() : <p>No history available</p>}
        </div>
      </div>
    </div>
   );
  };
  
  export default DocumentDetails;  
