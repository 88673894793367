const wordList = [
  'how', 'many', 'records', 'are', 'there', 'with', 'names', 'similar', 'to', 'count', 'find', 'search', 'query', 'data', 'schema','attribute', 'match', 'contains', 'starts', 'ends', 'value', 'greater', 
  'less', 'than', 'equals', 'not', 'and', 'or', 'between', 'in', 'list', 
  'order', 'by', 'asc', 'desc', 'limit', 'offset', 'first', 'last', 'group',
  'sum', 'average', 'min', 'max' , 'filter', 'sort', 'range', 'exact', 'all', 'any', 'contains', 'exists', 
  'include', 'excluding', 'excluding', 'including', 'optional', 'required', 
  'field', 'column', 'row', 'record', 'table', 'database', 'join', 'left', 
  'right', 'inner', 'outer', 'full', 'union', 'intersect', 'except', 
  'difference', 'difference', 'unique', 'distinct', 'unique', 'distinct', 
  'aggregate', 'function', 'method', 'class', 'object', 'variable', 'constant', 
  'parameter', 'argument', 'return', 'type', 'string', 'integer', 'float', 
  'double', 'boolean', 'true', 'false', 'null', 'null', 'void', 'array', 
  'list', 'set', 'map', 'dictionary', 'key', 'value', 'pair', 'tuple', 
  'object', 'json', 'xml', 'html', 'text', 'number', 'id', 'primary', 
  'foreign', 'foreign', 'index', 'constraint', 'condition', 'if', 'else', 
  'else', 'then', 'elseif', 'switch', 'case', 'break', 'continue', 'loop', 
  'for', 'while', 'do', 'while', 'until', 'repeat', 'try', 'catch', 'finally', 
  'throw', 'exception', 'error', 'message', 'warning', 'alert', 'log', 'trace', 
  'debug', 'info', 'warn', 'error', 'fatal', 'critical', 'validation', 
  'validate', 'rule', 'check', 'ensure', 'assert', 'assert', 'test', 'unit', 
  'integration', 'acceptance', 'performance', 'load', 'stress', 'capacity', 
  'benchmark', 'benchmark', 'speed', 'time', 'response', 'latency', 'throughput', 
  'rate', 'frequency', 'interval', 'period', 'duration', 'timeout', 'retry', 
  'retry', 'backup', 'restore', 'snapshot', 'restore', 'backup', 'restore', 
  'snapshot', 'restore', 'recover', 'recovery', 'failover', 'redundancy', 
  'replication', 'replication', 'shard', 'partition', 'partition', 'table', 
  'database', 'schema', 'metadata', 'info', 'system', 'user', 'admin', 'owner', 
  'admin', 'owner', 'developer', 'developer', 'team', 'group', 'organization', 
  'organization', 'role', 'permission', 'access', 'control', 'level', 'security', 
  'auth', 'authentication', 'authorization', 'token', 'session', 'login', 
  'password', 'credentials', 'encryption', 'decryption', 'cipher', 'hash', 
  'digest', 'signature', 'sign', 'verify', 'checksum', 'checksum', 'code', 
  'compile', 'build', 'deploy', 'release', 'version', 'tag', 'branch', 'merge', 
  'commit', 'pull', 'push', 'clone', 'fork', 'fork', 'repository', 'repo', 
  'issue', 'bug', 'ticket', 'story', 'task', 'subtask', 'epic', 'milestone', 
  'sprint', 'iteration', 'release', 'release', 'release', 'pipeline', 'pipeline', 
  'workflow', 'workflow', 'workflow', 'workflow', 'workflow', 'workflow', 
  'automation', 'continuous', 'integration', 'continuous', 'deployment', 
  'pipeline', 'workflow', 'step', 'stage', 'job', 'task', 'action', 'trigger', 
  'event', 'event', 'handler', 'listener', 'listener', 'callback', 'callback', 
  'callback', 'callback', 'callback', 'callback', 'callback', 'promise', 
  'promise', 'promise', 'promise', 'promise', 'async', 'async', 'await'
];

export default wordList;
