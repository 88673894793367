import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './DataAnalysis.css';
import wordList from './wordList';

const DataAnalysis = ({ showSuggestions, selectedAttribute, selectedSchema }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchQuery.length > 0 && showSuggestions) {
      const queryWords = searchQuery.split(' ');
      const lastWord = queryWords[queryWords.length - 1];
      const newSuggestions = wordList.filter(word =>
        word.toLowerCase().startsWith(lastWord.toLowerCase())
      );
      setSuggestions(newSuggestions.slice(0, 10));
    } else {
      setSuggestions([]);
    }
  }, [searchQuery, showSuggestions]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    const queryWords = searchQuery.split(' ');
    queryWords[queryWords.length - 1] = suggestion;
    setSearchQuery(queryWords.join(' '));
    setSuggestions([]);
  };

  const handleSearch = async () => {
    setSuggestions([]);
    setResults([]);
    if (!searchQuery.trim()) {
      setError('Search query cannot be empty.');
      return;
    }
  
    if (!selectedSchema) {
      setError('Please select a schema.');
      return;
    }
  
    // Transform selectedAttribute by replacing commas and dots with underscores, and remove spaces
    const transformedAttribute = selectedAttribute
      .replace(/,/g, '_')   
      .replace(/\./g, '_')  
      .replace(/\s+/g, ''); 

    console.log(transformedAttribute);
      
    setLoading(true);
    setError('');
    setHasSearched(true);
  
    try {
      const response = await fetch('/api/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          collection_name: transformedAttribute,
          query: searchQuery
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        const results = data.results;
  
        const fieldNames = new Set();
        results.forEach(result => {
          const document = result.document;
          Object.keys(document).forEach(field => {
            if (field !== '_id') {
              fieldNames.add(field);
            }
          });
        });
  
        const names = results.map(result => {
          const document = result.document;
          const documentId = document._id;
          const fields = [...fieldNames].map(field => document[field] || '').join(' ');
          return { fields, documentId };
        });
  
        setResults(names);
      } else {
        setError(data.detail || 'Error processing search.');
      }
    } catch (error) {
      console.error('Error performing search:', error);
      setError('Error performing search.');
    } finally {
      setLoading(false);
    }
  };  

  const handleResultClick = (id) => {
    navigate(`/document-details?id=${encodeURIComponent(id)}`);
  };  

  return (
    <div className="data-insights-container">
      <h3>AI Search with Match Attributes</h3>
      <div className="search-bar">
        <input
          type="text"
          value={searchQuery}
          onChange={handleInputChange}
          placeholder="Enter search query..."
          id="typeAheadSearch"
          name="typeAheadSearch"
        />
        <button onClick={handleSearch} disabled={loading} className="button">
          {loading ? 'Searching...' : 'Search'}
        </button>
        {suggestions.length > 0 && (
          <ul className="suggestions-list1">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="suggestion-item1"
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="results">
        {hasSearched && results.length === 0 ? (
          <p></p>
        ) : (
          <ul>
            {results.map((result, index) => (
              <li key={index}>
                <a href="" onClick={() => handleResultClick(result.documentId)} className="result-link">
                  {result.fields}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DataAnalysis;
