import React, { useState } from 'react';

const TogglePasswordVisibility = ({ inputId }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    const passwordInput = document.getElementById(inputId);
    if (passwordInput) {
      setIsPasswordVisible((prev) => !prev);
      passwordInput.type = isPasswordVisible ? 'password' : 'text';
    }
  };

  return (
    <i
      className={`ri ${isPasswordVisible ? 'ri-eye-fill' : 'ri-lock-2-fill'}`}
      onClick={togglePasswordVisibility}
    ></i>
  );
};

export default TogglePasswordVisibility;