import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { firebaseConfig } from './config';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, collection, query , where , getDocs, serverTimestamp, updateDoc , getDoc, setDoc } from 'firebase/firestore';

import logo from './logo.png';
import bgImage from './login-bg2.png';
import TogglePasswordVisibility from './TogglePasswordVisibility';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const LoginForm = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const expression = /^[^@]+@\w+(\.\w+)+\w$/
    if(expression.test(email)==true){
        //Email is good
        return true;
    }
    else{
        //Email is not good
        return false;
    }
  };

  const validatePassword = (password) => {
    if(password.length < 6){
        return false;
    }
    else{
        return true;
    }
  };

  const login = () => {
    if (!validateEmail(email) || !validatePassword(password)) {
      alert("Please check email and password (must be at least 6 characters)");
      return;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const uid = user.uid;

      // Fetch organization information and user role
      const { orgId, orgName, userRole } = await fetchOrgInfoAndRole(email);

      if (!orgId || !orgName) {
        setErrorMessage("Organization ID or name not found.");
        return;
      }

      if (!userRole) {
        setErrorMessage("User role could not be determined.");
        return;
      }

      await updateUserData(uid, userRole, orgId); // Update user data

      // Handle login based on user role
      if (userRole === 'admin') {
        localStorage.setItem('loggedInOrgId', orgId);
        localStorage.setItem('orgName', orgName);
        localStorage.setItem('isAdmin', 'true');
        navigate('/console');
        setErrorMessage(''); 
      } else if (userRole === 'user') {
        // Navigate to schema creation form for users
        localStorage.setItem('loggedInOrgId', orgId);
        localStorage.setItem('orgName', orgName);
        localStorage.setItem('userEmail', email);
        localStorage.setItem('isAdmin', 'false');
        navigate('/user_panel');
        setErrorMessage('');
      } else {
        setErrorMessage("Invalid user role.");
      }
    } catch (error) {
      setErrorMessage(error.message);
      console.error(error);
    }
  };

  // Function to update user data
  async function updateUserData(uid, userRole, orgId) {
    try {
      if (userRole === 'admin') {
        // Update admin data
        const docRef = doc(db, 'Admins', orgId);
        await updateDoc(docRef, {
          LastLogin: serverTimestamp()
        });
      } else if (userRole === 'user') {
        // Update user data
        const usersCollectionRef = collection(db, 'Users');
        const userDocRef = doc(usersCollectionRef, orgId);

        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          userData[uid] = { ...userData[uid], LastLogin: serverTimestamp() };
          await setDoc(userDocRef, userData);
        } else {
          console.error("User document not found for orgId:", orgId);
        }
      } else {
        console.error("Invalid user role:", userRole);
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  }

  // Function to fetch organization ID based on user's email for both admins and users
  async function fetchOrgIdByEmail(email) {
    try {
      // Look for user data in Users collection
      const usersQuerySnapshot = await getDocs(collection(db, 'Users'));
      for (const userDoc of usersQuerySnapshot.docs) {
        const userData = userDoc.data();
        const userIds = Object.keys(userData);
        for (const userId of userIds) {
          const userDetails = userData[userId];
          if (userDetails.Email === email) {
            // Return the organization ID if email matches
            return userDoc.id;
          }
          console.log(userDoc.id);
        }
      }
      
      // If organization ID not found in Users collection, check Admins collection
      const adminsQuerySnapshot = await getDocs(collection(db, 'Admins'));
      for (const adminDoc of adminsQuerySnapshot.docs) {
        const adminData = adminDoc.data();
        if (adminData.Email === email) {
          // Return the organization ID if the email matches
          return adminDoc.id;
        }
      }

      // Return null if organization ID is not found
      return null;
    } catch (error) {
      console.error("Error fetching organization ID:", error);
      return null;
    }
  }

  // Combined organization info and role fetching logic
  async function fetchOrgInfoAndRole(email) {
    try {
      // Fetch organization ID
      const orgId = await fetchOrgIdByEmail(email);
  
      if (!orgId) {
        console.error("Organization ID not found for email:", email);
        return { orgId: null, orgName: null, userRole: null };
      }
  
      // Fetch organization document using the retrieved organization ID
      const orgDocRef = doc(db, 'Admins', orgId);
      const orgDocSnapshot = await getDoc(orgDocRef);
  
      if (orgDocSnapshot.exists()) {
        const orgData = orgDocSnapshot.data();
        const orgName = orgData.OrganizationName;
  
        // Check if the user is an admin based on the organization document
        if (orgData.Email === email) {
          return { orgId, orgName, userRole: 'admin' };
        }
      } else {
        console.error("Organization document not found for orgId:", orgId);
        return { orgId: null, orgName: null, userRole: null };
      }

      const orgData = orgDocSnapshot.data();
      const orgName = orgData.OrganizationName;
      // If not found in Admins collection, check Users collection for user role
      const userDocRef = doc(db, 'Users', orgId);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const userIds = Object.keys(userData);
  
        for (const userId of userIds) {
          const userDetails = userData[userId];
          if (userDetails.Email === email) {
            return { orgId, orgName, userRole: 'user' };
          }
        }
      } else {
        console.error("User document not found for orgId:", orgId);
        return { orgId, orgName, userRole: null };
      }
  
      return { orgId, orgName, userRole: null }; // Return null if user role is not determined
    } catch (error) {
      console.error("Error fetching organization info and role:", error);
      return { orgId: null, orgName: null, userRole: null };
    }
  }  

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div>
      {errorMessage && <p className="error-message">{errorMessage}</p>} 
      <img src={logo} alt="logo" className="login__logo" />
      <div className="login">
          <div className="login__header">
              <div className="login__text">
                  <h1 className="head1">Welcome to Neural Labs</h1>
                  <p className="login__description">This is our secure login portal. Whether you're an administrator or a user, once logged in, you'll gain access to our intuitive schema creation interface.</p>
              </div>
          </div>
          <img src={bgImage} alt="image" className="login__bg" />
          <form id="MainForm" action="" className="login__form" onSubmit={handleLogin}>
              <h1 className="login__title">Login</h1>
              <div className="login__inputs">
                  <div className="login__box">
                      <input type="email" placeholder="Username/Email ID" id="emailInp" value={email} onChange={handleChangeEmail} required className="login__input" />
                      <i className="ri-mail-fill"></i>
                  </div>
                  <div className="login__box">
                      <input type="password" placeholder="Password" id="passwordInp" value={password} onChange={handleChangePassword} required className="login__input" /><TogglePasswordVisibility inputId="passwordInp" />
                      {/* <i className="ri-lock-2-fill" id="togglePassword"></i> */}
                  </div>
              </div>
              <div className="login__check">
                  <div className="login__check-box">
                      <input type="checkbox" className="login__check-input" id="user-check" />
                      <label htmlFor="user-check" className="login__check-label">Remember me</label>
                  </div>
                  <div className='login__register'>
                    <Link to="/forgot_password">Forgot Password?</Link>
                  </div>
              </div>
              <button type="submit" className="login__button" onClick={login}>Login</button>
              <div className='login__register'>
                Don't have an account?  <Link to="/register">Register</Link>
              </div>
          </form>
      </div>
    </div>
  );
};

export default LoginForm;