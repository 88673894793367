import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './SidePanelUser.css';
import { FaSignOutAlt } from 'react-icons/fa';

const SidePanelUser = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showConsole, setShowConsole] = useState(false);
  const [loginDate, setLoginDate] = useState('');
  const [authExpiry, setAuthExpiry] = useState('');
  const [jwtToken, setJwtToken] = useState('');
  const [timeRemaining, setTimeRemaining] = useState('');
  const [renewalStatus, setRenewalStatus] = useState('Pending');
  const [isJwtVisible, setIsJwtVisible] = useState(false);
  const [showJwtToken, setShowJwtToken] = useState(true);
  const [isSessionPrompted, setIsSessionPrompted] = useState(false);
  const [showLogoutPrompt, setShowLogoutPrompt] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('showConsole') === 'true') {
      setIsExpanded(true);
      retrieveStatistics();
    } else {
      retrieveStatistics();
    }
  }, [location]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsExpanded(true);
        retrieveStatistics();
      }
    });
    return () => unsubscribe();
  }, [location]);

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    if (!showConsole) {
      setIsExpanded(false);
    }
  };

  const handleChocolateBarClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickConsole = () => {
    setShowConsole(true);
    setIsExpanded(true);
    setIsJwtVisible(false); 
    retrieveStatistics(); 
  };

  const handleLogoutClick = () => {
    setShowLogoutPrompt(true);
  };

  const handleLogoutConfirm = (confirm) => {
    setShowLogoutPrompt(false);
    if (confirm) {
      getAuth().signOut().then(() => {
        localStorage.clear();  
        navigate('/login_page');    
      });
    }
  };

  useEffect(() => {
    const savedLoginDate = localStorage.getItem('loginDate');
    const savedAuthExpiry = localStorage.getItem('authExpiry');
    const savedJwtToken = localStorage.getItem('jwtToken');
    if (savedLoginDate) setLoginDate(savedLoginDate);
    if (savedAuthExpiry) setAuthExpiry(savedAuthExpiry);
    if (savedJwtToken) setJwtToken(savedJwtToken);
    retrieveStatistics();
  }, []);

  const retrieveStatistics = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setTimeout(async () => {
        try {
          const token = await user.getIdToken(true);
          setJwtToken(token);
          localStorage.setItem('jwtToken', token);
          const decodedToken = jwtDecode(token);
          const expiryDate = new Date(decodedToken.exp * 1000).toLocaleString();
          setAuthExpiry(expiryDate);
          localStorage.setItem('authExpiry', expiryDate);

          const db = getFirestore();
          const orgId = localStorage.getItem('loggedInOrgId');
          const userRole = localStorage.getItem('isAdmin') === 'true' ? 'Admins' : 'Users';
          const docRef = doc(db, userRole, orgId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            const lastLoginTime = userRole === 'Admins' ? data?.LastLogin : data[user?.uid]?.LastLogin;
            if (lastLoginTime) {
              const loginDateValue = lastLoginTime.toDate().toLocaleString();
              setLoginDate(loginDateValue);
              localStorage.setItem('loginDate', loginDateValue);
              const countdownTime = new Date(new Date(loginDateValue).getTime() + 60 * 60 * 1000);
              const updateCountdown = () => {
                const now = new Date();
                const difference = countdownTime - now;
                if (difference > 0) {
                  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                  const seconds = Math.floor((difference % (1000 * 60)) / 1000);
                  setTimeRemaining(`${minutes}m ${seconds}s`);
                } else {
                  setTimeRemaining('Expired');
                }
              };
              updateCountdown();
              const intervalId = setInterval(updateCountdown, 1000);
              return () => clearInterval(intervalId);
            }
          }
        } catch (error) {
          console.error("Error fetching statistics:", error);
        }
      }, 2000);
    }
  };

  const sessionPromptedRef = useRef(false);

  const handleSessionExpiry = () => {
    sessionPromptedRef.current = true;
    const stayLoggedIn = window.confirm('Your session is about to expire. Do you want to stay logged in?');
    if (stayLoggedIn) {
      refreshToken();
    } else {
      handleLogout();
    }
  };

  const refreshToken = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      user.getIdToken(true)
        .then((token) => {
          setJwtToken(token);
          const decodedToken = jwtDecode(token);
          const newExpiryDate = new Date(decodedToken.exp * 1000);
          const newExpiry = newExpiryDate.toLocaleString();
          setAuthExpiry(newExpiry);
          localStorage.setItem('authExpiry', newExpiry);
          const updateCountdown = () => {
            const now = new Date();
            const difference = newExpiryDate - now;
            if (difference > 0) {
              const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
              const seconds = Math.floor((difference % (1000 * 60)) / 1000);
              setTimeRemaining(`${minutes}m ${seconds}s`);
            } else {
              setTimeRemaining('Expired');
            }
          };
          updateCountdown();
          const intervalId = setInterval(updateCountdown, 1000);
          return () => clearInterval(intervalId);
        })
        .catch((error) => {
          console.error('Error refreshing token:', error);
          handleLogout();
        })
        .finally(() => {
          sessionPromptedRef.current = false;
        });
    } else {
      sessionPromptedRef.current = false;
      handleLogout();
    }
  };

  const handleLogout = () => {
    getAuth()
      .signOut()
      .then(() => {
        navigate('/login_page');
        localStorage.clear();
      })
      .finally(() => {
        setIsSessionPrompted(false);
      });
  };

  const handleSchemaCreation = () => {
    navigate('/create_schema');
  };

  const handleLoadData = () => {
    navigate('/load_data');
  };

  const handleAISearch = () => {
    navigate('/ai_search');
  };

  useEffect(() => {
    if (!showConsole) {
      setIsJwtVisible(true);
      retrieveStatistics(); 
    }
  }, [showConsole]);

  useEffect(() => {
    if (timeRemaining === 'Expired' && !sessionPromptedRef.current) {
      handleSessionExpiry();
    }
  }, [timeRemaining]);

  return (
    <div>
      <div className="custom-side-panel-user">
        <div
          className="custom-chocolate-bar-user"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleChocolateBarClick}
        >
          {[...Array(3)].map((_, row) => (
            <div key={row} className="custom-chocolate-row-user">
              {[...Array(3)].map((_, col) => (
                <div key={col} className="custom-chocolate-box-user"></div>
              ))}
            </div>
          ))}
        </div>
        {isExpanded && (
          <div
            className="custom-expanded-panel-user"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="custom-applications-user">Applications</div>
            <div className="custom-console-button-user" onClick={handleClickConsole}>
              Console
            </div>
          </div>
        )}
        {showConsole && (
          <div className="custom-console-user">
            <div className="custom-horizontal-tiles-user">
              <div className="custom-tile-user" onClick={handleSchemaCreation}>
                <img src="createSchema.jpg" alt="Create Schema" className="custom-tile-image-user" />
                <div className="custom-tile-title-user">Create Schema</div>
              </div>
              <div className="custom-tile-user" onClick={handleLoadData}>
                <img src="loadData.jpg" alt="Load Data" className="custom-tile-image-user" />
                <div className="custom-tile-title-user">Load Data</div>
              </div>
              <div className="custom-tile-user" onClick={handleAISearch}>
                <img src="AiSearch.jpg" alt="AI Search" className="custom-tile-image-user" />
                <div className="custom-tile-title-user">AI Search</div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isJwtVisible && (
        <div className="custom-logout-section-user">
          <div className="custom-login-info-user">
            <div>Login Date: {loginDate}</div>
            <div>Token Expiry: {authExpiry}</div>
            <div>Time Remaining: {timeRemaining}</div>
          </div>
          {showJwtToken && (
            <div className="custom-jwt-section-user">
              <div className="custom-jwt-label-user">JWT Token:</div>
              <textarea
                className="custom-jwt-textarea-user"
                value={jwtToken}
                readOnly
                onClick={() => setShowJwtToken(true)}
              />
            </div>
          )}
          <div className="custom-logout-button-user" onClick={handleLogoutClick}>
            <FaSignOutAlt /> 
          </div>
          {showLogoutPrompt && (
            <div className="custom-logout-prompt-user">
              <div>Are you sure you want to logout?</div>
              <button onClick={() => handleLogoutConfirm(true)}>Yes</button>
              <button onClick={() => handleLogoutConfirm(false)}>No</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SidePanelUser;
