import React from 'react';
import './App.css';
import SidePanel from './SidePanel';
import LoginForm from './login';
import RegisterForm from './RegisterForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import CreateSchema from './CreateSchema';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SidePanelUser from './SidePanelUser';
import LoadData from './LoadData';
import AISearch from './AISearch';
import DocumentDetails from './DocumentDetails';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/login_page" element={<LoginForm />} />
        <Route path="/forgot_password" element={<ForgotPasswordForm />} />
        <Route path="/console" element={<SidePanel />} />
        <Route path="/user_panel" element={<SidePanelUser />} />
        <Route path="/create_schema" element={<CreateSchema />} />
        <Route path="/load_data" element={<LoadData />} />
        <Route path="/ai_search" element={<AISearch />} />
        <Route path="/document-details" element={<DocumentDetails />} />
      </Routes>
    </Router>
  );
};

export default App;