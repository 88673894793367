import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './CreateSchema.css';
import { firebaseConfig } from './config';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, collection, setDoc, getDoc, getDocs, serverTimestamp, query, orderBy } from 'firebase/firestore';
import AISearch from './AISearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const CreateSchema = () => {
  const navigate = useNavigate(); 
  const [showPopup, setShowPopup] = useState(false);
  const [newObjectName, setNewObjectName] = useState("");
  const [step, setStep] = useState(1);
  const [attributes, setAttributes] = useState([{ name: "", type: "", matchValue: "" }]);
  const [selectedFormat, setSelectedFormat] = useState("simple");
  const [formError, setFormError] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [schemas, setSchemas] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [viewMode, setViewMode] = useState("attributes");
  const [loading, setLoading] = useState(false);
  const [importError, setImportError] = useState("");
  const [selectedSection, setSelectedSection] = useState('objectTypes');

  useEffect(() => {
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      setUserEmail(storedEmail);
    }
    fetchSchemas();
  }, []);

  const fetchSchemas = async () => {
    try {
      const orgId = localStorage.getItem('loggedInOrgId');
      const dataModelRef = doc(db, 'DataModel', orgId, 'schemas', 'currentSchema');
      const dataModelDoc = await getDoc(dataModelRef);
      const latestVersion = dataModelDoc.exists() ? dataModelDoc.data() : null;
  
      if (latestVersion && latestVersion.objectTypes) {
        const schemasData = latestVersion.objectTypes.map(objectType => ({
          schemaName: objectType.uid.split("/")[2],
          attributes: Array.isArray(objectType.attributes) ? objectType.attributes.map(attr => ({
            name: attr.name || "",
            type: attr.type || "",
            matchValue: attr.matchAttribute === true ? "Yes" : "No",  
            searchable: attr.searchable === true ? "Yes" : "No"  
          })) : []
        }));
        setSchemas(schemasData);
      } else {
        setSchemas([]);
      }
    } catch (error) {
      console.error("Error fetching schemas: ", error);
    }
  };  

  const handleAddAttribute = () => {
    setAttributes([...attributes, { name: "", type: "", matchValue: "" }]);
  };  

  const handleAttributeChange = (index, field, value, parentIndexes = []) => {
    const updatedAttributes = [...attributes];
  
    const updateNestedAttribute = (attrs, nestedIndexes) => {
      const [firstIndex, ...remainingIndexes] = nestedIndexes;
  
      if (remainingIndexes.length === 0) {
        attrs[firstIndex][field] = value;
      } else {
        updateNestedAttribute(attrs[firstIndex].nestedAttributes, remainingIndexes);
      }
    };
  
    if (parentIndexes.length === 0) {
      updatedAttributes[index][field] = value;
    } else {
      updateNestedAttribute(updatedAttributes, [index, ...parentIndexes]);
    }
  
    setAttributes(updatedAttributes);
  };  

  const handleNestedAdd = (index, parentIndexes = []) => {
    const updatedAttributes = [...attributes];
  
    const addNestedAttribute = (attrs, nestedIndexes) => {
      const [firstIndex, ...remainingIndexes] = nestedIndexes;
  
      if (remainingIndexes.length === 0) {
        if (!attrs[firstIndex].nestedAttributes) {
          attrs[firstIndex].nestedAttributes = [{ name: "", type: "", matchValue: "" }];
        } else {
          attrs[firstIndex].nestedAttributes.push({ name: "", type: "", matchValue: "" });
        }
      } else {
        if (!attrs[firstIndex].nestedAttributes) {
          attrs[firstIndex].nestedAttributes = [];
        }
        addNestedAttribute(attrs[firstIndex].nestedAttributes, remainingIndexes);
      }
    };
  
    addNestedAttribute(updatedAttributes, [index, ...parentIndexes]);
  
    setAttributes(updatedAttributes);
  };  
  
  const validateAttributes = () => {
    if (attributes.length === 0) {
      setFormError("At least one attribute is required.");
      return false;
    }

    for (const attr of attributes) {
      if (attr.name.trim() === "" || attr.type.trim() === "") {
        setFormError("All attributes must have a name and type.");
        return false;
      }

      if (attr.nestedAttributes) {
        for (const nestedAttr of attr.nestedAttributes) {
          if (nestedAttr.name.trim() === "" || nestedAttr.type.trim() === "") {
            setFormError("All nested attributes must have a name and type.");
            return false;
          }
        }
      }
    }

    setFormError("");
    return true;
  };

  const handleSaveAndContinue = () => {
    if (!newObjectName.trim()) {
      setFormError("Name of Object or Entity is required.");
    } else {
      setFormError("");
      setStep(2);
    }
  };

  const handleSaveSchemaWrapper = () => {
    handleSaveSchema(userEmail);
  };

  const getUserRole = async (userEmail, orgId) => {
    const userDocRef = doc(db, "Users", orgId);
    const userDocSnapshot = await getDoc(userDocRef);
  
    if (!userDocSnapshot.exists()) {
      throw new Error("User document does not exist.");
    }
  
    const userData = userDocSnapshot.data();
    const userIds = Object.keys(userData);
  
    for (const userId of userIds) {
      const userDetails = userData[userId];
      if (userDetails.Email === userEmail) {
        return userDetails.Role;
      }
    }
  
    throw new Error("User role not found.");
  };  

  // Helper function to process attributes recursively
  const processAttributes = (attributes) => {
    return attributes.map(attr => ({
      name: attr.name,
      type: attr.type || "",
      matchValue: attr.matchValue || "",
      searchable: attr.searchable || "",
      nestedAttributes: attr.nestedAttributes ? processAttributes(attr.nestedAttributes) : []
    }));
  };

  // Updated processMatchAttributes function
  const processMatchAttributes = (matchAttributes) => {
    const flatComplexAttributes = matchAttributes.complexAttributes.map(group =>
      group.join(', ')
    );

    return {
      simpleAttributes: matchAttributes.simpleAttributes,
      complexAttributes: flatComplexAttributes
    };
  };

  const handleImportSchema = async (event) => {
    const userEmail = localStorage.getItem('userEmail');
    const orgId = localStorage.getItem('loggedInOrgId');
    const isAdmin = localStorage.getItem('isAdmin') === 'true';
  
    try {
      if (!isAdmin) { 
        const userRole = await getUserRole(userEmail, orgId);
        if (!userRole.includes('UPDATE_SCHEMA')) {
          alert("You don't have permissions to update the schema.");
          return;
        }
      }
  
      const file = event.target.files[0];
      if (file && file.type === "application/json") {
        setLoading(true);
        try {
          const fileContent = await file.text();
          const importedData = JSON.parse(fileContent);
  
          console.log("Imported JSON content:", importedData);
  
          const objectTypes = importedData.objectTypes || importedData.ObjectTypes;
          if (!objectTypes || !Array.isArray(objectTypes)) {
            throw new Error("Imported JSON does not contain a valid ObjectTypes array.");
          }
  
          const dataModelRef = collection(db, 'DataModel', orgId, 'schemas');
          const historyRef = collection(db, 'DataModelHistory', orgId, 'history');
          const historySnapshot = await getDocs(query(historyRef, orderBy('version', 'desc')));
          const latestVersionDoc = historySnapshot.docs[0];
          const latestVersion = latestVersionDoc ? latestVersionDoc.data() : null;
  
          const newVersionNumber = latestVersion ? parseInt(latestVersion.version.split('v')[1]) + 1 : 1;
          const newVersionId = `v${newVersionNumber}`;
  
          let foundNewAttributes = false;
          const updatedSchemas = [];
  
          const processAttributes = (attributes, parentUid) => {
            const allMatchValuedAttributes = [];
            const simpleAttributes = [];
            const twoValuedPairs = [];
            const threeValuedPairs = [];
  
            const attrs = attributes.map(attr => {
              const newAttr = {
                uid: `${parentUid}/${attr.name}`,
                name: attr.name,
                type: attr.type,
                label: attr.label || attr.name,
                matchAttribute: attr.matchAttribute || false,
                searchable: attr.searchable || false
              };
  
              if (attr.matchAttribute) {
                allMatchValuedAttributes.push(attr.name);
                simpleAttributes.push(attr.name);
              }
  
              if (attr.attributes && attr.attributes.length > 0) {
                newAttr.nestedAttributes = processAttributes(attr.attributes, newAttr.uid);
              }
  
              return newAttr;
            });
  
            // Generate two-valued and three-valued pairs
            for (let i = 0; i < allMatchValuedAttributes.length; i++) {
              for (let j = i + 1; j < allMatchValuedAttributes.length; j++) {
                twoValuedPairs.push([allMatchValuedAttributes[i], allMatchValuedAttributes[j]].join(", "));
                for (let k = j + 1; k < allMatchValuedAttributes.length; k++) {
                  threeValuedPairs.push([allMatchValuedAttributes[i], allMatchValuedAttributes[j], allMatchValuedAttributes[k]].join(", "));
                }
              }
            }
  
            let complexAttributes = threeValuedPairs.concat(twoValuedPairs).slice(0, 4);
  
            return {
              attrs,
              matchAttributes: {
                simpleAttributes,
                complexAttributes
              }
            };
          };
  
          objectTypes.forEach(importedAttr => {
            const { attrs, matchAttributes } = processAttributes(importedAttr.attributes, importedAttr.uid);
            const schema = updatedSchemas.find(s => s.uid === importedAttr.uid);
  
            if (schema) {
              schema.attributes = attrs;
              schema.matchAttributes = matchAttributes;
            } else {
              foundNewAttributes = true;
              updatedSchemas.push({
                uid: importedAttr.uid,
                attributes: attrs,
                matchAttributes: matchAttributes
              });
            }
          });
  
          if (!foundNewAttributes) {
            setImportError("Error: No new attributes found in the imported schema.");
            setLoading(false);
            event.target.value = ""; // Reset file input
            return;
          }
  
          const dataModelDoc = doc(db, 'DataModel', orgId, 'schemas', 'currentSchema');
          await setDoc(dataModelDoc, {
            uid: "datamodel",
            objectTypes: updatedSchemas
          });
  
          const newVersionData = {
            uid: "datamodel",
            version: newVersionId,
            objectTypes: updatedSchemas,
            updatedBy: userEmail,
            updatedDate: serverTimestamp(),
          };
  
          await setDoc(doc(historyRef, newVersionId), newVersionData);
  
          setShowPopup(false);
          fetchSchemas();
          setLoading(false);
        } catch (error) {
          console.error("Error importing schema: ", error);
          setImportError("Error importing schema. Please check the file format and content.");
          setLoading(false);
        }
      } else {
        setImportError("Please select a valid JSON file.");
      }
    } catch (error) {
      console.error("Error during schema import: ", error);
      alert("Failed to import schema.");
    }
  };  

  const handleExportSchema = async () => {
    try {
      const orgId = localStorage.getItem('loggedInOrgId');
      const schemaRef = collection(db, 'DataModel', orgId, 'schemas');
      const schemaDoc = await getDoc(doc(schemaRef, 'currentSchema'));
      const latestVersion = schemaDoc.exists() ? schemaDoc.data() : null;
  
      if (!latestVersion) {
        console.error("No schema found to export");
        return;
      }
  
      // Process attributes, including nested ones
      const processAttributes = (attributes) => {
        return attributes.map(attr => {
          const processedAttr = {
            uid: attr.uid,
            name: attr.name,
            label: attr.label || attr.name.charAt(0).toUpperCase() + attr.name.slice(1),
            matchAttribute: attr.matchAttribute === true,  // Ensure boolean conversion
            searchable: attr.searchable === true  // Ensure boolean conversion
          };
  
          // Recursively process nested attributes
          if (attr.nestedAttributes && attr.nestedAttributes.attrs) {
            processedAttr.nestedAttributes = processAttributes(attr.nestedAttributes.attrs);
          }
  
          return processedAttr;
        });
      };
  
      // Process object types
      const objectTypes = latestVersion.objectTypes.map(objectType => {
        const matchAttributes = {
          simpleAttributes: objectType.matchAttributes.simpleAttributes || [],
          complexAttributes: objectType.matchAttributes.complexAttributes || []
        };
  
        return {
          uid: objectType.uid,
          attributes: processAttributes(objectType.attributes || []),
          matchAttributes: matchAttributes
        };
      });
  
      const exportData = {
        uid: "datamodel",
        objectTypes: objectTypes
      };
  
      // Create JSON file and trigger download
      const dataStr = JSON.stringify(exportData, null, 2);
      const dataBlob = new Blob([dataStr], { type: 'application/json' });
      const url = window.URL.createObjectURL(dataBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `datamodel.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error exporting schema: ", error);
    }
  };  
  
  const handleSaveSchema = async (userEmail) => {
    const orgId = localStorage.getItem('loggedInOrgId'); 
    const isAdmin = localStorage.getItem('isAdmin') === 'true';
  
    try {
      if (!isAdmin) { 
        const userRole = await getUserRole(userEmail, orgId);
    
        if (!userRole.includes('CREATE_SCHEMA')) {
          alert("You don't have permissions to create the schema.");
          return;
        }
      }
  
      const isValid = validateAttributes();
      if (!isValid) {
        return;
      }
  
      if (!userEmail) {
        setFormError("User email is missing. Cannot save schema.");
        return;
      }
  
      const dataModelRef = collection(db, 'DataModel', orgId, 'schemas');
      const historyRef = collection(db, 'DataModelHistory', orgId, 'history');
      const dataModelDoc = await getDoc(doc(dataModelRef, 'currentSchema'));
      const currentDataModel = dataModelDoc.exists() ? dataModelDoc.data() : { ObjectTypes: [] };
  
      const historySnapshot = await getDocs(query(historyRef, orderBy('version', 'desc')));
      const latestVersionDoc = historySnapshot.docs[0];
      const latestVersion = latestVersionDoc ? latestVersionDoc.data() : null;
  
      const newVersionNumber = latestVersion ? parseInt(latestVersion.version.split('v')[1]) + 1 : 1;
      const newVersionId = `v${newVersionNumber}`;
  
      const updatedObjectTypes = currentDataModel.ObjectTypes ? [...currentDataModel.ObjectTypes] : [];
      const existingSchemaIndex = updatedObjectTypes.findIndex(objectType => objectType.uid.split("/")[2] === newObjectName);
  
      const parseAttributes = (attrs, parentName = '') => {
        return attrs.map(attr => {
          const { nestedAttributes, ...rest } = attr;
          const attrName = parentName ? `${parentName}.${attr.name}` : attr.name;
          if (nestedAttributes && nestedAttributes.length > 0) {
            rest.nestedAttributes = parseAttributes(nestedAttributes, attrName);
          }
          return {
            uid: `datamodel/ObjectTypes/${newObjectName}/${attrName}`,
            ...rest,
            name: attrName
          };
        });
      };
  
      const attributesWithMatchAttributes = parseAttributes(attributes);
      const allMatchValuedAttributes = [];
      const simpleAttributes = [];
      const twoValuedPairs = [];
      const threeValuedPairs = [];
  
      const collectMatchValuedAttributes = (attrs) => {
        attrs.forEach(attr => {
          if (attr.matchValue === "Yes") {
            allMatchValuedAttributes.push(attr.name);
            simpleAttributes.push(attr.name);
          }
          if (attr.nestedAttributes && attr.nestedAttributes.length > 0) {
            collectMatchValuedAttributes(attr.nestedAttributes);
          }
        });
      };
  
      collectMatchValuedAttributes(attributesWithMatchAttributes);
  
      // Generate two-valued pairs
      for (let i = 0; i < allMatchValuedAttributes.length; i++) {
        for (let j = i + 1; j < allMatchValuedAttributes.length; j++) {
          twoValuedPairs.push([allMatchValuedAttributes[i], allMatchValuedAttributes[j]].join(", "));
        }
      }
  
      // Generate three-valued pairs
      for (let i = 0; i < allMatchValuedAttributes.length; i++) {
        for (let j = i + 1; j < allMatchValuedAttributes.length; j++) {
          for (let k = j + 1; k < allMatchValuedAttributes.length; k++) {
            threeValuedPairs.push([allMatchValuedAttributes[i], allMatchValuedAttributes[j], allMatchValuedAttributes[k]].join(", "));
          }
        }
      }
  
      let complexAttributes = [];
      if (threeValuedPairs.length > 0) {
        complexAttributes.push(threeValuedPairs[0]);
      }
      for (let i = 0; i < twoValuedPairs.length && complexAttributes.length < 4; i++) {
        complexAttributes.push(twoValuedPairs[i]);
      }
      complexAttributes = complexAttributes.slice(0, 4);
  
      const newObjectType = {
        uid: `datamodel/ObjectTypes/${newObjectName}`,
        attributes: attributesWithMatchAttributes,
        matchAttributes: {
          simpleAttributes: simpleAttributes,
          complexAttributes: complexAttributes
        }
      };
  
      if (existingSchemaIndex !== -1) {
        updatedObjectTypes[existingSchemaIndex] = newObjectType;
      } else {
        updatedObjectTypes.push(newObjectType);
      }
  
      const docRef = doc(dataModelRef, 'currentSchema');
      await setDoc(docRef, {
        uid: "datamodel",
        ObjectTypes: updatedObjectTypes
      });
  
      const newVersionData = {
        uid: "datamodel",
        version: newVersionId,
        ObjectTypes: updatedObjectTypes,
        updatedBy: userEmail,
        updatedDate: serverTimestamp(),
      };
  
      await setDoc(doc(historyRef, newVersionId), newVersionData);
  
      setShowPopup(false);
      setNewObjectName("");
      setStep(1);
      setAttributes([{ name: "", type: "", matchValue: "", searchable: "No" }]);
  
      fetchSchemas();
    } catch (error) {
      console.error("Error saving schema: ", error);
      alert("Failed to save schema.");
    }
  };  
  
  const handleDeleteSchema = async (schemaName) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the schema "${schemaName}"?`);
    if (!confirmDelete) {
      return;
    }
  
    const userEmail = localStorage.getItem('userEmail');
    const orgId = localStorage.getItem('loggedInOrgId');
    const isAdmin = localStorage.getItem('isAdmin') === 'true';
  
    try {
      if (!isAdmin) { 
        const userRole = await getUserRole(userEmail, orgId);
    
        if (!userRole.includes('DELETE_SCHEMA')) {
          alert("You don't have permissions to delete the schema.");
          return;
        }
      }
  
      const dataModelRef = doc(db, 'DataModel', orgId, 'schemas', 'currentSchema');
      const dataModelDoc = await getDoc(dataModelRef);
      const currentDataModel = dataModelDoc.exists() ? dataModelDoc.data() : null;
  
      if (!currentDataModel) {
        console.error("No schema found to delete");
        return;
      }
  
      const updatedObjectTypes = currentDataModel.ObjectTypes.filter(objectType => objectType.uid.split("/")[2] !== schemaName);
  
      const updatedDataModel = {
        uid: "datamodel",
        ObjectTypes: updatedObjectTypes
      };
  
      await setDoc(dataModelRef, updatedDataModel);
  
      const historyRef = collection(db, 'DataModelHistory', orgId, 'history');
      const historySnapshot = await getDocs(query(historyRef, orderBy('version', 'desc')));
      const latestVersionDoc = historySnapshot.docs[0];
      const latestVersion = latestVersionDoc ? latestVersionDoc.data() : null;
  
      if (!latestVersion) {
        console.error("No schema found in history to update");
        return;
      }
  
      const newVersionNumber = latestVersion ? parseInt(latestVersion.version.split('v')[1]) + 1 : 1;
      const newVersionId = `v${newVersionNumber}`;
  
      const newVersionData = {
        uid: "datamodel",
        version: newVersionId,
        ObjectTypes: updatedObjectTypes,
        updatedBy: userEmail,
        updatedDate: serverTimestamp(),
      };
  
      await setDoc(doc(historyRef, newVersionId), newVersionData);
  
      setSelectedSchema(null);
  
      fetchSchemas();
    } catch (error) {
      console.error("Error deleting schema: ", error);
      alert("Failed to delete schema.");
    }
  };  
  
  const resetState = () => {
    setNewObjectName("");
    setStep(1);
    setAttributes([{ name: "", type: "", matchValue: "" }]);
    setSelectedFormat("simple");
    setFormError("");
  };

  const toggleSelectedSchema = (schema) => {
    setSelectedSchema(prevSchema => prevSchema === schema ? null : schema);
    setImportError("");
    setViewMode("attributes");
  };

  const toggleViewMode = (mode) => {
    setViewMode(mode);
  };

  const handleSectionClick = (section) => {
    if (selectedSection === section) {
      setSelectedSection('objectTypes');
    } else {
      setSelectedSection(section);
      setSelectedSchema(null); // Deselect any selected schema
    }
  }

  const handleBack = () => {
    navigate('/console?showConsole=true');
  };

  return (
    <div className="create-schema-container">
      <div className="left-panel">
        <button className="button-back" onClick={handleBack}>Back</button>
        <h2>Create Schema</h2><hr></hr>
        <div className="objectTypes">
          <ul type="*">
            <li className="objectValues" onClick={() => handleSectionClick('dataInsights')}>
              <FontAwesomeIcon icon={faStar} /> AI Query
            </li>
          </ul>
          <h3>Object Types</h3>
          <ul>
            {schemas.map((schema, index) => (
              <li className="objectValues" key={index} onClick={() => toggleSelectedSchema(schema)}>
                {schema.schemaName}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="right-panel">
        {selectedSection === 'dataInsights' ? (
          <AISearch />
        ) : selectedSchema ? (
          <>
            <div className="schema-details">
              <h4>Object Type: {selectedSchema.schemaName}</h4>
              <h4>No. of Attributes: {selectedSchema.attributes.length}</h4>
            </div>
            <div className="toggle-buttons">
              <button onClick={() => toggleViewMode("attributes")} className={viewMode === "attributes" ? "active" : ""}>
                Attributes
              </button>
              <button onClick={() => toggleViewMode("matchValues")} className={viewMode === "matchValues" ? "active" : ""}>
                Match Valued Attributes
              </button>
              <button className="delete-btn" onClick={() => handleDeleteSchema(selectedSchema.schemaName)}>Delete</button>
            </div>
            <div className="attributes-display">
              {viewMode === "attributes" ? (
                <table className="attributes-table">
                  <thead>
                    <tr>
                      <th>Attribute Name</th>
                      <th>Type</th>
                      <th>Searchable</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedSchema.attributes.map((attr, index) => (
                      <tr key={index}>
                        <td>{attr.name}</td>
                        <td>{attr.type}</td>
                        <td>{attr.searchable}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <table className="match-values-table">
                  <thead>
                    <tr>
                      <th>Attribute Name</th>
                      <th>Type</th>
                      <th>Searchable</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedSchema.attributes
                      .filter(attr => attr.matchValue === "Yes")
                      .map((attr, index) => (
                        <tr key={index}>
                          <td>{attr.name}</td>
                          <td>{attr.type}</td>
                          <td>{attr.searchable}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </>
        ) : (
          <>
            <h3>Object Schemas</h3>
            <div className="button-group">
              <label className="import-btn">
                <i className="fas fa-file-import"></i>&nbsp; Import Schema
                <input type="file" accept=".json" onChange={handleImportSchema} style={{ display: 'none' }} />
              </label>
              <button onClick={() => handleExportSchema(selectedSchema)} className="export-btn">
                <i className="fas fa-file-export"></i> Export Schema
              </button>
            </div>
            {importError && (
              <div className="import-error">
                <span className="red-cross">✖</span>
                {importError}
              </div>
            )}
            {loading && (
              <div className="loading-spinner">
                <i className="fas fa-spinner fa-spin"></i> Loading...
              </div>
            )}
            <table className="schemas-table">
              <thead>
                <tr>
                  <th>Object Name</th>
                  <th>No. of Attributes</th>
                </tr>
              </thead>
              <tbody>
                {schemas.map((schema, index) => (
                  <tr key={index}>
                    <td>{schema.schemaName}</td>
                    <td>{schema.attributes.length}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="create-new-wrapper">
              <h4 className="create-new-h4">Create New</h4>
              <button className="create-new-btn" onClick={() => { setShowPopup(true); resetState(); }}>
                <span className="plus-icon">+</span>
              </button>
            </div>
          </>
        )}
        {showPopup && (
          <div className="popup">
            <div className="popup-inner">
              {step === 1 && (
                <>
                  <button className="close-btn" onClick={() => setShowPopup(false)}>×</button>
                  <label>Name of Object or Entity:</label>
                  <input
                    type="text"
                    value={newObjectName}
                    onChange={(e) => setNewObjectName(e.target.value)}
                    style={{ width: "100%" }}
                  />
                  {formError && <div className="form-error">{formError}</div>}
                  <div className="popup-actions">
                    <button className="cancel-btn" onClick={() => setShowPopup(false)}>Cancel</button>
                    <button className="save-btn" onClick={handleSaveAndContinue}>Save & Continue</button>
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <button className="close-btn" onClick={() => setShowPopup(false)}>×</button>
                  <div className="json-format-selection">
                    <label>
                      <input
                        type="radio"
                        value="simple"
                        checked={selectedFormat === "simple"}
                        onChange={() => setSelectedFormat("simple")}
                      />
                      Simple
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="nested"
                        checked={selectedFormat === "nested"}
                        onChange={() => setSelectedFormat("nested")}
                      />
                      Nested
                    </label>
                  </div>
                  <div className="attributes-wrapper">
                    <div className="attributes-container">
                      {attributes.map((attr, index) => (
                        <div key={index} className="attribute-row">
                          <input
                            type="text"
                            placeholder="Attribute Name"
                            value={attr.name}
                            onChange={(e) => handleAttributeChange(index, "name", e.target.value)}
                          />
                          <select
                            value={attr.type}
                            onChange={(e) => handleAttributeChange(index, "type", e.target.value)}
                          >
                            <option value="">Select Type</option>
                            <option value="INT">INT</option>
                            <option value="CHAR">CHAR</option>
                            <option value="VARCHAR">VARCHAR</option>
                            <option value="DATE">DATE</option>
                            {selectedFormat === "nested" && (
                              <option value="NESTED">NESTED</option>
                            )}
                          </select>
                          <select
                            value={attr.matchValue}
                            onChange={(e) => handleAttributeChange(index, "matchValue", e.target.value)}
                          >
                            <option value="">Match Value</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                          <select
                            value={attr.searchable}
                            onChange={(e) => handleAttributeChange(index, "searchable", e.target.value)}
                          >
                            <option value="">Searchable</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                          {attr.type === "NESTED" && (
                            <button
                              className="nested-add-btn"
                              onClick={() => handleNestedAdd(index)}
                            >
                              +
                            </button>
                          )}
                          {attr.nestedAttributes && (
                            <div className="nested-attributes-container">
                              {attr.nestedAttributes.map((nestedAttr, nestedIndex) => (
                                <div key={nestedIndex} className="nested-attribute-row">
                                  <input
                                    type="text"
                                    placeholder="Nested Attribute Name"
                                    value={nestedAttr.name}
                                    onChange={(e) =>
                                      handleAttributeChange(index, 'name', e.target.value, [nestedIndex])
                                    }
                                  />
                                  <select
                                    value={nestedAttr.type}
                                    onChange={(e) => handleAttributeChange(index, "type", e.target.value, [nestedIndex])}
                                  >
                                    <option value="">Select Type</option>
                                    <option value="INT">INT</option>
                                    <option value="CHAR">CHAR</option>
                                    <option value="VARCHAR">VARCHAR</option>
                                    <option value="DATE">DATE</option>
                                    {selectedFormat === "nested" && (
                                      <option value="NESTED">NESTED</option>
                                    )}
                                  </select>
                                  <select
                                  value={nestedAttr.matchValue}
                                  onChange={(e) =>
                                    handleAttributeChange(index, 'matchValue', e.target.value, [nestedIndex])
                                  }
                                >
                                  <option value="">Match Value</option>
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </select>
                                <select
                                  value={nestedAttr.searchable}
                                  onChange={(e) =>
                                    handleAttributeChange(index, 'searchable', e.target.value, [nestedIndex])
                                  }
                                >
                                  <option value="">Searchable</option>
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </select>
                                {nestedAttr.type === "NESTED" && (
                                  <button
                                    className="nested-add-btn"
                                    onClick={() => handleNestedAdd(index, [nestedIndex])}
                                  >
                                    +
                                  </button>
                                )}
                                {nestedAttr.nestedAttributes && (
                                  <div className="nested-attributes-container">
                                    {nestedAttr.nestedAttributes.map((deepNestedAttr, deepNestedIndex) => (
                                      <div key={deepNestedIndex} className="nested-attribute-row">
                                        <input
                                          type="text"
                                          placeholder="Deep Nested Attribute Name"
                                          value={deepNestedAttr.name}
                                          onChange={(e) =>
                                            handleAttributeChange(
                                              index,
                                              'name',
                                              e.target.value,
                                              [nestedIndex, deepNestedIndex]
                                            )
                                          }
                                        />
                                        <select
                                          value={deepNestedAttr.type}
                                          onChange={(e) =>
                                            handleAttributeChange(
                                              index,
                                              'type',
                                              e.target.value,
                                              [nestedIndex, deepNestedIndex]
                                            )
                                          }
                                        >
                                          <option value="">Select Type</option>
                                          <option value="INT">INT</option>
                                          <option value="CHAR">CHAR</option>
                                          <option value="VARCHAR">VARCHAR</option>
                                          <option value="DATE">DATE</option>
                                          <option value="NESTED">NESTED</option>
                                        </select>
                                        <select
                                          value={deepNestedAttr.matchValue}
                                          onChange={(e) =>
                                            handleAttributeChange(
                                              index,
                                              'matchValue',
                                              e.target.value,
                                              [nestedIndex, deepNestedIndex]
                                            )
                                          }
                                        >
                                          <option value="">Match Value</option>
                                          <option value="No">No</option>
                                          <option value="Yes">Yes</option>
                                        </select>
                                        <select
                                          value={deepNestedAttr.searchable}
                                          onChange={(e) =>
                                            handleAttributeChange(
                                              index,
                                              'searchable',
                                              e.target.value,
                                              [nestedIndex, deepNestedIndex]
                                            )
                                          }
                                        >
                                          <option value="">Searchable</option>
                                          <option value="No">No</option>
                                          <option value="Yes">Yes</option>
                                        </select>
                                        {deepNestedAttr.type === "NESTED" && (
                                          <button
                                            className="nested-add-btn"
                                            onClick={() => handleNestedAdd(index, [nestedIndex, deepNestedIndex])}
                                          >
                                            +
                                          </button>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <button className="add-attribute-btn" onClick={handleAddAttribute}>+ Add Attribute</button>
                {formError && <div className="form-error">{formError}</div>}
                <div className="popup-actions">
                  <button className="cancel-btn" onClick={() => setShowPopup(false)}>Cancel</button>
                  <button className="save-btn" onClick={handleSaveSchemaWrapper}>Save</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  </div>
  );
};

export default CreateSchema;
